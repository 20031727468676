import { useEffect, useState } from 'react'
import RadioButton from 'Components/Forms/RadioButton/RadioButton'
import { useOutletContext, useNavigate } from 'react-router-dom'
import PrimaryButton from 'Components/Buttons/PrimaryButton'
import SecondaryButton from 'Components/Buttons/SecondaryButton'
import Cookies from 'js-cookie'

function VisibleProfile() {




    const [data, setData, formations, setFormations, experiences, setExperiences] = useOutletContext()
    const [userId, setUserId] = useState(null)

    const navigate = useNavigate()

    let fetchPromise = []

    const token = Cookies.get("token")


    useEffect(() => {
        fetch(`${process.env.REACT_APP_API}user/infoPerso`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            }
        })
            .then(res => res.json())
            .then(data => setUserId(data.id))
    }, [])


    const visibiliteHandler = (e) => {
        let value = e.target.value
        let newData = { ...data }
        if (value === "true") {
            newData.visibilite = true
        } else {
            newData.visibilite = false

        }

        setData(newData)
    }


    const saveInfoUser = () => {
        let body = {
            titre: data.titre,
            idgrade: data.idgrade,
            tags: data.tags,
            localisation: data.localisation,
            workExperience: data.workExperience,
            disponibility: data.disponibility,
            timeExperience: data.timeExperience,
            department: data.department,
            remote: data.remote,
            workTime: data.workTime,
            expertComptable: data.expertComptable === true ? true : false,
            inscritOrdre: data.inscritOrdre === true ? true : false,
            ordreRegional: data.ordreRegional,
            commissaireCompte: data.commissaireCompte === true ? true : false,
            inscritCNCC: data.inscritCNCC === true ? true : false,
            compagnieRattache: data.compagnieRattache,
            sUPRA: data.supra
        };

        if (data.availabilityDate !== "") {
            body.availabilityDate = data.availabilityDate
        }

        if (data.jtm !== "") {
            body.jtm = parseInt(data.jtm)
        }

        if (data.salaire !== "") {
            body.salaire = parseInt(data.salaire)
        }

        if (data.visibilite === true) {
            body.visibility = data.visibilite
        }

        const promise = fetch(
            `${process.env.REACT_APP_API}user/infoPerso/modify/${userId}`,
            {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/merge-patch+json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(body)
            }
        )


        fetchPromise.push(promise)



    }

    const saveExperiences = () => {
        experiences.map(experience => {
            let body;

            if (experience.date_start !== "" && experience.date_end !== "") {
                const { id, date_end, date_start, ...payload } = experience
                body = payload
            } else {
                const { id, date_end, date_start, dateEnd, ...payload } = experience
                body = payload
            }

            const promise = fetch(
                `${process.env.REACT_APP_API}user/experience/create`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(body)
                }
            )

            fetchPromise.push(promise)
        })
    }

    const saveFormation = () => {
        formations.map(formation => {
            const { id, date_end, date_start, ...payload } = formation

            const promise = fetch(
                `${process.env.REACT_APP_API}user/formation/create`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(payload)
                }
            )

            fetchPromise.push(promise)


        })
    }

    const save = () => {
        saveInfoUser()
        saveExperiences()
        saveFormation()

        Promise.all(fetchPromise)
            .then(responses => {
                const promises = responses.map(response => response.json());

                return Promise.all(promises);
            })
            .then(dataArray => {
                navigate("/mon-espace/Dashboard")
            })
            .catch(error => {
            });
    }

    return (
        <div className='mb-[50px] flex flex-col items-center gap-[30px] bg-white p-4'>
            <div className='mb-[20px]'>
                <h2>Êtes-vous prêt à rendre votre profil visible ?</h2>
            </div>
            <div className='flex gap-2'>
                <RadioButton name="visibilite" label={"Oui"} onChange={visibiliteHandler} value={true} checked={data.visibilite === true} />
                <RadioButton name="visibilite" label={"Non"} onChange={visibiliteHandler} value={false} checked={data.visibilite === false} />
            </div>
            <div className='w-full flex justify-between'>
                <SecondaryButton onClick={() => navigate("/parcours-inscription/supra")} text={"précédent"} />
                <PrimaryButton text={"terminer"} onClick={() => save()} />
            </div>
        </div>
    )
}

export default VisibleProfile