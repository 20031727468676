// ------------------NOUVEAU-------------
import React, { useEffect, useState, useContext } from "react";
import { Link, Navigate, useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { AuthContext } from "Context/AuthProvider";
import { AlertMessageContext } from "Context/AlertMessageProvider";
import cookie from "js-cookie";
import axios from "axios";
import jwt from "jwt-decode";
import PrimaryButton from "Components/Buttons/PrimaryButton";

export default function Formulaire({ setSideText }) {
  const { value } = useContext(AuthContext);
  const { alert } = useContext(AlertMessageContext);
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const location = useLocation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm({ mode: "onChange" });
  const [roles, setRoles] = useState(null);
  const [identity, setIdentity] = useState(null);
  const [didReadLegal, setDidReadLegal] = useState(false)

  // Envoie du formulaire
  const onSubmit = (data) => {
    setIsLoading(true)
    // on change le role en tableau car la requete veut un tableau et pas une string
    console.log(data.roles)
    data.roles = [data.roles];
    data.email = location.state.email;
    axios.post(process.env.REACT_APP_API + "inscriptionGoogle/" + roles,
      JSON.stringify(data),
      {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then((res) => {
        setIsLoading(false)
        console.log(res)
        cookie.set("token", res.data.token, {
          secure: true,
          expires: 2,
          sameSite: 'none'
        });
        cookie.set("StatusConnexion", false, {
          secure: true,
          expires: 2,
          sameSite: 'none'
        });
        const decodedToken = jwt(res.data.token);
        value.setUser(decodedToken);
        value.setIsLoggedIn(true);
        alert.successAlert("Votre inscription a bien été validée.");
        // Redirection en fonction du role
        roles === "user" && navigate("/parcours-inscription");
        roles === "client" && navigate("/mon-espace/Dashboard");
      }).catch((err) => {
        setIsLoading(false)
      })
  };

  useEffect(() => {
    if (location.state) {
      setValue("firstName", location.state.firstName)
      setValue("lastName", location.state.lastName)
    } else {
      navigate("/Connexion")
    }
  }, [location.state])

  const handleroles = (e) => {
    setSideText(e.target.value);
    setIdentity(e.target.value)
    if (["Cabinet-conseil", "Cabinet-daudit-dexpertise-comptable", "entreprise"].includes(e.target.value)) {
      setRoles("client");
    } else if (["Candidat", "Consultant", "Etudiant"].includes(e.target.value)) {
      setRoles("user");
    }
  };

  function toogleReadLegal(e) {
    setDidReadLegal(e.target.checked)
  }

  return (
    <div className="">
      <form
        action="submit"
        className="flex flex-col"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="form-input text-black">
          <div className="flex flex-col pt-4">
            <label htmlFor="roles">Identité</label>
            <select
              name="roles"
              className={`px-4 py-2 border rounded-full bg-white w-full  ${errors.roles && ""
                }`}
              onInput={handleroles}
              {...register("roles", { required: "Champ requis" })}
            >
              <option value="" className="hidden"></option>
              <option value="entreprise">Entreprise</option>
              <option value="Cabinet-conseil">
                {" "}
                Cabinet de conseil {" "}
              </option>
              <option value="Cabinet-daudit-dexpertise-comptable">
                {" "}
                Cabinet d'audit et/ou d'expertise comptable{" "}
              </option>
              <option value="Etudiant">Etudiant</option>
              <option value="Candidat">Candidat</option>
              <option value="Consultant">Consultant</option>
            </select>
            {errors.roles && (
              <span className="text-red-500">
                {" "}
                {errors.roles.message}{" "}
              </span>
            )}
          </div>
          {roles === "client" ? (
            <>
              <div className="flex flex-col pt-4">
                <label htmlFor="Entreprise">Entreprise</label>
                <input
                  type="text"
                  name="Entreprise"
                  placeholder=""
                  className={`px-4 py-2 border rounded-full flex ${errors.company && ""
                    }`}
                  {...register("company", {
                    required: "Champ requis",
                  })}
                />
                {errors.company && (
                  <span className="text-red-500">
                    {" "}
                    {errors.company.message}{" "}
                  </span>
                )}
              </div>
              <div className="flex flex-col pt-4">
                <label htmlFor="title">Fonction</label>
                <input
                  type="text"
                  name="title"
                  placeholder=""
                  className={`px-4 py-2 border rounded-full flex ${errors.title && ""
                    }`}
                  {...register("title", {
                    required: "Champ requis",
                  })}
                />
                {errors.title && (
                  <span className="text-red-500">
                    {" "}
                    {errors.title.message}{" "}
                  </span>
                )}
              </div>
            </>
          ) : null}

          {identity === "Cabinet-daudit-dexpertise-comptable" &&
            <div className="flex flex-col pt-4">
              <label htmlFor="numberSupra">Numéro d'inscription à l'ordre (SUPRA)</label>
              <input
                type="text"
                name="numberSupra"
                placeholder=""
                className={`px-4 py-2 border rounded-full flex ${errors.numberSupra && ""
                  }`}
                {...register("numberSupra", {
                  required: "Champ requis",
                })}
              />
              {errors.numberSupra && (
                <span className="text-red-500">
                  {" "}
                  {errors.numberSupra.message}{" "}
                </span>
              )}
            </div>}

          <div className="flex flex-col xl:flex-row gap-4">
            <div className="flex flex-col pt-4">
              <label htmlFor="firstName">Prénom</label>
              <input
                type="text"
                name="firstName"
                placeholder=""
                className={`px-4 py-2 border rounded-full w-full ${errors.firstName && ""
                  }`}
                {...register("firstName", {
                  required: "Champ requis",
                })}
              />
              {errors.firstName && (
                <span className="text-red-500">
                  {" "}
                  {errors.firstName.message}{" "}
                </span>
              )}
            </div>

            <div className="flex flex-col pt-4">
              <label htmlFor="lastName">Nom</label>
              <input
                type="text"
                name="lastName"
                placeholder=""
                className={`px-4 py-2 border rounded-full w-full ${errors.lastName && ""
                  }`}
                {...register("lastName", {
                  required: "Champ requis",
                })}
              />
              {errors.lastName && (
                <span className="text-red-500">
                  {" "}
                  {errors.lastName.message}{" "}
                </span>
              )}
            </div>
          </div>

          <div className="inline-flex items-center mt-4">
            <label className="switch inline-flex relative" >
              <input type="checkbox" onClick={toogleReadLegal} />
              <span className="slider hover:cursor-pointer"></span>
            </label>
            <p className="text-[14px] italic ml-4 self-center">
              J'ai lu et accepte les <Link to="/CGU" target="_blank" className="text-middleblue hover:text-[#497EA6]">conditions générales d'utilisation (CGU)                             </Link>
            </p>
          </div>

        </div>
        <div className="pt-8 w-fit mx-auto">
          <PrimaryButton isLoading={isLoading} disabled={!didReadLegal || roles === null} text={"Je m'inscris"} type="submit" />
        </div>
      </form>
    </div>
  );
}
