import React, { useState, useEffect } from "react";
import { useNavigate, Link} from "react-router-dom";
// import Profil from "../../../../assets/profil1.png";
import BannerImage from "../../../../assets/PanaProfil.jpg";
import Recherche from "assets/svg/SearchHome";
import Location from "assets/svg/Location";
import RegionSelector from "./RegionSelector/RegionSelector";

export default function Hero(props) {
  const [searchTerm, setSearchTerm] = useState("");
  const [localisation, setLocalisation] = useState("")
  const [queryUrl, setQueryUrl] = useState("");
  const navigate = useNavigate();


  const generateQuery = (searchTerm, localisation) => {
    let params = []
    let query = ""
    if (searchTerm.length > 0) {
      params.push(`searchTerm=${searchTerm}`)
    }

    if (localisation.length > 0) {
      if (searchTerm.length === 0) {
        params.push(`searchTerm=`)
        params.push(`localisation=${localisation}`)
      } else {
        params.push(`localisation=${localisation}`)
      }
    }

    if (params.length > 0) {
      query += `?${params.join("&")}`
    }

    setQueryUrl(query)
  }


  useEffect(() => {
    generateQuery(searchTerm,localisation)
  },[localisation,searchTerm])

  const handleSearchTerm = (e) => {
    let value = e.target.value;
    setSearchTerm(value);
  };

  const handleLocalisation = (e) => {
    let value = e.target.value;
    setLocalisation(value);
  };

  // const handleAfficher = () => {
  //   props.onSearch(searchTerm);
  //   props.onLocalisation(localisation)
  // };

  const handleEnterKeyPress = (e) => {
    if (e.key === "Enter") {
      if ((searchTerm.length > 0 ) || (localisation.length > 0 )) {
        // handleAfficher();
        navigate(`/les-profils${queryUrl}`);
      }
    }
  };

  return (
    <div>
      <div className='flex flex-col justify-center lg:justify-between md:flex-row mb-[100px]'>
        <div className='flex flex-col justify-center'>
          <h1 className="text-center lg:text-start xl:text-[px]">
          Premier Hub des métiers de la <span className="text-[#497EA6]">finance</span>,  de l'<span className="text-[#497EA6]">audit</span> et d'<span className="text-[#497EA6]">expertise comptable</span>           
          </h1>
          <p className="text-center md:text-start my-5 text-[32px] font-bold">
          Trouver un profil <span className="text-[#497EA6]">financier</span> devient <span className="text-[#497EA6]">simple</span> et <span className="text-[#497EA6]">évident</span> 
          </p>
            <p className="text-center md:text-start italic">
              * Tous contrats : Stage, Alternance, CDD, CDI, Mission de conseil, Freelance, Management de transition
            </p>
        </div>
        <div>
          <img src={BannerImage} className="hidden lg:block" />
        </div>
      </div>

      <section className="flex flex-col gap-4 xl:flex-row justify-between items-center xl:justify-around bg-[#EFF5FA] py-[30px] px-[30px] rounded-[20px] 2xl:justify-start bigscreen:text-[20px] bigscreen:px-[40px]">
        <div className='flex items-center w-full xl:w-[480px] h-[39px] bg-[#FFFFFF] border-[1.5px] border-[#BDD8EC] rounded-[100px]'>
          <div className='flex grow items-center ml-[15px]'>
            <Recherche />
            <input className="focus-visible:outline-none text-[#455A64] w-4/5 ml-[15px]" list="data-list" value={searchTerm} onChange={handleSearchTerm} onKeyUp={handleEnterKeyPress} type="text" placeholder="Comptable, Chef de projet..." />
          </div>
        </div>
        <div className='flex items-center w-full xl:w-[480px] h-[39px] bg-[#FFFFFF] border-[1.5px] border-[#BDD8EC] rounded-[100px]'>
          <div className='flex grow items-center ml-[15px]'>
            <Location width={18} height={18} />
            {/* <input className="focus-visible:outline-none w-4/5 ml-[15px]" value={localisation} onChange={handleLocalisation} type="text" placeholder="Localisation (ex: Île-de-France...)" /> */}
            <RegionSelector handleLocalisation={handleLocalisation} handleEnterKeyPress={handleEnterKeyPress} />
          </div>
        </div>
        <div className='flex flex-col sm:flex-row gap-x-4 items-center 2xl:grow 2xl:justify-end'>
          <Link to={`/les-profils${queryUrl}`}>
            <button type="button" className="hover:bg-[#497EA6] font-normal bg-[#5B9ECF] w-[168px] h-[39px] text-white rounded">
              Trouver un profil
            </button>
          </Link>
          <span>ou</span>
          <Link to={`/les-opportunites${queryUrl}`}>
            <span className="underline underline-offset-8 font-semibold text-[#497EA6] whitespace-nowrap">Je recherche une opportunité</span>
          </Link>
        </div>
      </section>
    </div>
  );
}

