import { Link } from "react-router-dom";

export default function FreelanceCard4() {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 items-center border-[4px] rounded-[8px] px-4 py-6 my-8">
      <div className="col-span-2 font-medium">
        <h1 className="text-[32px]">Vous souhaitez protéger votre entreprise et votre foyer avec des solutions d'assurance sur mesure adaptées à vos besoins spécifiques ?</h1>
        <p className="text-[16px] font-medium mt-4">Découvrez comment nos offres d'assurance RC Pro, mutuelle et prévoyance peuvent sécuriser votre activité et vous offrir une tranquillité d'esprit.</p>
        <p className="text-[16px] font-medium mt-4">Prenez rendez-vous dès maintenant pour une consultation personnalisée.</p>
         <Link to="/RendezVous" className="bg-[#5B9ECF] hover:bg-[#2D77AD] text-white py-2 px-4 rounded-[5px] inline-block mt-6">Prendre rendez vous</Link>
      </div>
      <div className="mt-8 md:mt-0 text-center md:text-right">
        <h2 className="text-[#5B9ECF] text-[48px] my-auto">Devis personnalisé</h2>
      </div>
    </div>
  )
}