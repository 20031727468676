import { useContext, useEffect, useState } from 'react'

import { useParams, useNavigate } from "react-router-dom";

import { isClient } from 'utils/roles';
import { AuthContext } from "Context/AuthProvider";

import PrimaryButton from "Components/Buttons/PrimaryButton";
import SecondaryButton from 'Components/Buttons/SecondaryButton';
import LikeButton from "./LikeButton";
import FavoriteButton from "./FavoriteButton";
import AppointmentModal from 'Modals/AppointmentModal';
import PdfTemplate from './PdfTemplate';
import axios from 'axios';
import SmallBadge from 'Components/Badge/SmallBadge';
import ProfilNoPicture from "assets/img/profilnodata.png";

import { RiDownload2Fill } from "react-icons/ri";
import { IoShareSocialSharp } from "react-icons/io5";

import { AlertMessageContext } from "Context/AlertMessageProvider";
import { ModalContext } from 'Context/ModalProvider';
import AvaibilityBadge from "Components/AvaibilityBadge/AvaibilityBadge";
import ShareModal from "Modals/ShareModal";
import jwtDecode from 'jwt-decode';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';



export default function PortfolioHeader({ skillPortfolio, isComptable }) {

    const { id } = useParams();
    const [irisLink, setIrisLink] = useState(null);

    const { openModal } = useContext(ModalContext);
    const { value } = useContext(AuthContext);
    const { alert } = useContext(AlertMessageContext);
    const navigate = useNavigate()

    const AppointmentModalHandler = () => {
        openModal("Rencontrer le profil", <AppointmentModal />)
    }

    function NotConnected() {
        alert.errorAlert("Vous devez être connecté pour pouvoir rencontrer ce profil")
        navigate("/Connexion")
    }

    const DownloadPorfolioHandler = () => {
        axios.get(process.env.REACT_APP_API + `dossierCompetence/${id}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            return PdfTemplate(res.data)
        })
    }

    const ShareHandler = () => {
        openModal("Partager", <ShareModal url={window.location.origin + "/dossier-de-competences/" + id} />)
    }

    function colorContrat(contrat) {
        // const colorContrat = { "Stage": "#B3D8F2", "Alternance": "#B3D8F2", "CDD": "#B3E7FC", "CDI": "#B3E7FC", "Mission": "#7CC8FF" }
        switch (contrat) {
            case "Stage":
                return "bg-[#B3D8F2]"
            case "Alternance":
                return "bg-[#B3D8F2]"
            case "CDD":
                return "bg-[#B3E7FC]"
            case "CDI":
                return "bg-[#B3E7FC]"
            case "Mission":
                return "bg-[#7CC8FF]"
            default:
                break;
        }
    }

    useEffect(() => {
        if (Cookies.get("token")) {
            const roles = jwtDecode(Cookies.get("token")).roles;
            if (roles.includes("ROLE_SUPER_ADMIN") || roles.includes("ROLE_ADMIN") || roles.includes("ROLE_OPERATEUR")) {
                var iri = `${process.env.REACT_APP_API}users/${id}`.replaceAll(":", "%3A");
                iri = iri.replaceAll("/", "%2F");
                setIrisLink(`${window.location.origin}/Iris/users/${iri}/show`)
            }
        }
    }, [])
    return (
        <div className="bg-white border p-4 rounded flex flex-col gap-4 mb-4">
            <div className="flex flex-col md:flex-row sm:justify-between gap-4">
                <div className="flex flex-col md:flex-row md:items-center gap-4">
                    <div className="flex flex-col items-center gap-2 shrink-0">
                        <img className="rounded-full w-[150px] h-[150px] object-cover" src={
                            skillPortfolio?.confidentiel ?
                                ProfilNoPicture
                                :
                                skillPortfolio?.filePath ?
                                    skillPortfolio.filePath : ProfilNoPicture} alt="" srcSet="" />
                        <span className="text-center">
                            <span className='font-bold'>ID: </span>
                            {irisLink ?
                                <Link target="_blank" to={irisLink} className='text-[#497EA6] underline'>{skillPortfolio?.anonymousId}</Link>
                                :
                                skillPortfolio?.anonymousId
                            }
                        </span>
                    </div>
                    <div>
                        {skillPortfolio?.jobTitle != null ? <p className="text-[2.2rem] font-bold text-[#5B9ECF] px-4">{skillPortfolio?.jobTitle.substring(0, 80)}</p> : null

                        }
                        <p className="text-[1.5rem] italic px-4">{skillPortfolio?.seniority.grade_type}</p>
                        <div className="grid grid-cols-1 md:grid-cols-3 divide-y md:divide-y-0 md:divide-x mt-4 gap-2">
                            <div className="px-4 justify-self-center md:justify-self-start">
                                <small className="font-medium">Disponibilité</small>
                                <div className="mt-2">
                                    <span className="flex items-center">{skillPortfolio ? <AvaibilityBadge avaibility={{ "disponibility": skillPortfolio.avaibility, "availability_date": skillPortfolio.avaibilityDate }} /> : null}</span>
                                </div>

                            </div>
                            <div className="px-4 justify-self-center md:justify-self-end">
                                <small className="font-medium">Type de contrat</small>
                                <div className="mt-2 flex flex-wrap gap-2">
                                    {
                                        skillPortfolio?.contracts.map((el, key) => {
                                            if (el === "Mission" && isComptable) {
                                                return
                                            }
                                            return <div key={key} className={colorContrat(el) + ` py-1 px-3 text-[#263238] rounded`} >{el}</div>
                                        }
                                        )
                                    }
                                </div>

                            </div>
                            <div className="px-4 justify-self-center md:justify-self-end">
                                <small className="font-medium">Années d’expérience</small>
                                <div className="mt-2">

                                    <span className="font-medium">{skillPortfolio?.timeExperiences}</span>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col gap-2">
                    {
                        isClient(value.user) ?
                            <PrimaryButton text={"Rencontrer ce profil"} onClick={() => AppointmentModalHandler()} />
                            :
                            value.user.roles == "guest" ?
                                <PrimaryButton text={"Rencontrer ce profil"} onClick={() => NotConnected()} />
                                :
                                null
                    }
                    <SecondaryButton text={"Dossier de compétences"} icon={<RiDownload2Fill />} onClick={() => DownloadPorfolioHandler()} />
                    <div className="flex items-center text-[1.5rem] flex gap-1 justify-end">
                        <LikeButton id={id} />
                        <FavoriteButton id={id} />
                    </div>
                    <div className="h-[35px] w-[35px] hover:bg-[#e2e8f0] hover:cursor-pointer ml-auto flex items-center justify-center rounded">
                        <IoShareSocialSharp onClick={ShareHandler} size={26} className="srink-0" />
                    </div>
                </div>
            </div>
        </div>
    )
}
