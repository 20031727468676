import React, { useState, useEffect } from "react";
import { FieldGuesser, ListGuesser, EditGuesser, InputGuesser, ShowGuesser, CreateGuesser } from "@api-platform/admin";
import { usePermissions, DeleteWithConfirmButton, useRecordContext, ReferenceInput, SelectInput, ReferenceField, useCreatePath, TextInput, BooleanInput } from "react-admin";
import axios from "axios";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import cookie from "js-cookie";

const IriToText = () => {
  const record = useRecordContext();
  var hardContrat = ["", "Stage", "Alternance", "CDD", "CDI", "", "Mission"]
  var textContrats = "";

  record.idContract.filter(item => {
    textContrats += hardContrat[item.slice(-1)] + ",";
  })
  if (textContrats !== "") {
    textContrats = textContrats.substring(0, textContrats.length - 1);
  }
  return textContrats;
}
const LimitDescription = ({ source }) => {
  const record = useRecordContext();
  if (!record.description) return null;
  if (record.description.length > 40) {
    return parse(record.description.substring(0, 40) + "...");
  } else {
    return parse(record.description);
  }
};
const ShowDescription = ({ source }) => {
  const record = useRecordContext();
  if (!record.description) return null;
  return parse(record.description)
};
const ClickOnClient = ({ source }) => {
  const createPath = useCreatePath();
  const record = useRecordContext();
  if (!record) return null;
  return <Link to={createPath({ resource: 'clients', type: 'show', id: record.id })} className="text-[#497EA6] hover:underline">{record[source]}</Link>
};

const NbPostuler = ({ source }) => {
  const record = useRecordContext();
  return record.postuler.length;
}

function ShowCandidatures({ source }) {
  const createPath = useCreatePath();
  const record = useRecordContext();
  const [candidatures, setCandidatures] = useState([]);

  useEffect(() => {
    if (record.postuler.length > 0) {
      axios.post(process.env.REACT_APP_API + "iris/candidatures", record.postuler, {
        headers: { Authorization: `Bearer ${cookie.get("token")}` }
      })
        .then(res => {
          setCandidatures(res.data)
        })
        .catch(err => {
          //
        })
    }
  }, [record])

  return (
    <ul>
      {candidatures.map(item => {
        return (
          <li>
            <Link to={createPath({ resource: 'users', type: 'show', id: item.idUser })} className="text-[#497EA6] hover:underline">{item.display}</Link>
          </li>
        )
      })}
    </ul>
  )
}

const ListFilters = [
  <TextInput label="Intitulé" source="intitule" alwaysOn />,
  <TextInput label="Description" source="description" alwaysOn />,
  <BooleanInput label="Valider" source="valider" />
];

const OffresList = (props) => {
  const { permissions } = usePermissions();
  return (
    <ListGuesser {...props} sort={{ field: 'date_modify', order: 'DESC' }} perPage={25} filters={ListFilters}>
      <ReferenceField label="Société" source="idClient" reference="clients">
        <ClickOnClient source="company" />
      </ReferenceField>
      <ReferenceField label="Prénom" source="idClient" reference="clients">
        <FieldGuesser source="first_name" />
      </ReferenceField>
      <ReferenceField label="Nom" source="idClient" reference="clients">
        <FieldGuesser source="last_name" />
      </ReferenceField>
      <ReferenceField label="Titre" source="idClient" reference="clients">
        <FieldGuesser source="title" />
      </ReferenceField>
      <ReferenceField label="Téléphone" source="idClient" reference="clients">
        <FieldGuesser source="mobile" />
      </ReferenceField>
      <ReferenceField label="Mail" source="idClient" reference="clients">
        <FieldGuesser source="email" />
      </ReferenceField>
      <FieldGuesser label="Intitulé" source="intitule" />
      <LimitDescription label="Description" source="Contrats" />
      <IriToText label="Contrats" source="id_contract" />
      <FieldGuesser label="Localisation" source="localisation" />
      <FieldGuesser label="TJM" source="jtm" />
      <FieldGuesser label="Salaire" source="salaire" />
      <FieldGuesser label="Disponibilité" source="availability" />
      <NbPostuler label="Candidatures" />
      <FieldGuesser label="Date de début" source="availability_date" />
      <FieldGuesser label="Date de la creation de l'offre" source="date_time_create" />
      <FieldGuesser label="Date de la dernière modification" source="date_modify" />
      <FieldGuesser label="Validé" source="valider" />
      {permissions === "ROLE_SUPER_ADMIN" &&
        <DeleteWithConfirmButton confirmContent="You will not be able to recover this record. Are you sure?" />
      }
    </ListGuesser>
  );
};
const OffresShow = (props) => {
  const { permissions } = usePermissions();
  return (
    <ShowGuesser {...props}>
      {permissions === "ROLE_SUPER_ADMIN" &&
        <DeleteWithConfirmButton confirmContent="You will not be able to recover this record. Are you sure?" />
      }
      <ReferenceField label="Client mail" source="idClient" reference="clients">
        <ClickOnClient source="email" />
      </ReferenceField>
      <ReferenceField label="Client prénom" source="idClient" reference="clients">
        <FieldGuesser source="first_name" />
      </ReferenceField>
      <ReferenceField label="Client nom" source="idClient" reference="clients">
        <FieldGuesser source="last_name" />
      </ReferenceField>
      <ReferenceField label="Client fonction" source="idClient" reference="clients">
        <FieldGuesser source="title" />
      </ReferenceField>
      <FieldGuesser label="Intitulé" source="intitule" />
      <ShowDescription label="Description" source="description" />
      <IriToText label="Contrats" source="id_contract" />
      <FieldGuesser label="Localisation" source="localisation" />
      <FieldGuesser label="TJM" source="jtm" />
      <FieldGuesser label="Salaire" source="salaire" />
      <FieldGuesser label="Disponibilité" source="availability" />
      <FieldGuesser label="Date de début" source="availability_date" />
      <FieldGuesser label="Date de la creation de l'offre" source="date_time_create" />
      <FieldGuesser source="valider" />
      <ShowCandidatures label="Candidatures" />
    </ShowGuesser>
  );
};
const OffresEdit = (props) => (
  <EditGuesser {...props}>
    <InputGuesser source="intitule" />
    <InputGuesser source="description" multiline fullWidth />
    <InputGuesser source="valider" />
  </EditGuesser>
);
const OffreSuperCreate = (props) => (
  <CreateGuesser {...props}>
    <ReferenceInput source="idClient" reference="clients">
      <SelectInput optionText="email" label="Client" />
    </ReferenceInput>
    <InputGuesser source="intitule" />
    <InputGuesser source="description" />
  </CreateGuesser>
);

export { OffresList, OffresShow, OffresEdit, OffreSuperCreate }