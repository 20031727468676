import { useEffect, useState, useContext } from 'react'
import { useNavigate, Outlet, NavLink, useOutletContext } from 'react-router-dom';
import { AlertMessageContext } from 'Context/AlertMessageProvider';

import Cookies from "js-cookie"
import axios from "axios";

import SmallPrimaryButton from 'Components/Buttons/SmallPrimaryButton'
import Checkbox from 'Components/Forms/CheckBox/Checkbox';
import NavItem from 'Components/NavUser/NavItem/NavItem';
import { FaUserTie } from "react-icons/fa";
import Badge from 'Components/Badge/Badge'
import TagForCard from 'Components/TagLevel/TagForCard';
import PrimaryButton from 'Components/Buttons/PrimaryButton';


function PersonalSkills() {
  const removeAccents = str => str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
  const [searchField, setSearchField] = useState("");
  const token = Cookies.get("token");
  const [userInformation, setUserInformation, filters] = useOutletContext();
  const [isLoading, setIsLoading] = useState(false)

  const [selectedTag, setSelectedTag] = useState([]);


  const [selectedSignet, setSelectedSignet] = useState("sectorialSkills");

  const { alert } = useContext(AlertMessageContext);
  const navigate = useNavigate()
  const [skills, setSkills] = useState({
    sectorialSkills: [],
    fonctionalSkills: [],
    computerSkills: [],
    languageSkills: [],
  });
  const [userSkills, setUserSkills] = useState({
    sectorialSkills: [],
    fonctionalSkills: [],
    computerSkills: [],
    languageSkills: [],
  });


  const onSubmit = async () => {
    setIsLoading(true)
    // On supprime les duplicats
    let unique = selectedTag.reduce(function (acc, curr) {
      if (acc.filter(item => item.tag === curr.tag).length === 0) {
        acc.push(curr);
      }
      return acc;
    }, []);
    const token = Cookies.get("token");
    const requestOptions = {
      method: "PATCH",
      body: JSON.stringify({ tags: unique }),
      headers: {
        "Content-Type": "application/merge-patch+json",
        "Authorization": `Bearer ${token}`
      }
    };

    try {
      const res = await fetch(process.env.REACT_APP_API + `user/infoPerso/modify/${userInformation.id}`, requestOptions)
      alert.successAlert("Modification enregistrée avec succès !");
      getProfile()
    } catch (error) {
      alert.successAlert("Une erreur s'est produite. Veuillez recharger la page.");
    }
    setIsLoading(false)
  }

  const getProfile = async () => {
    const res = await axios.get(
      process.env.REACT_APP_API + `user/infoPerso`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: token ? `Bearer ${token}` : ""
        }
      }
    )
    setUserInformation(res.data)
  }


  const handleSignet = (id) => {
    setSelectedSignet(id)
  }


  const getSkills = async () => {
    try {
      const { informatique, secteur, fonctionnelle, linguistique } = filters

      setSkills({
        sectorialSkills: secteur,
        fonctionalSkills: fonctionnelle,
        computerSkills: informatique,
        languageSkills: linguistique,
      })
      setUserSkills({
        sectorialSkills: [],
        fonctionalSkills: [],
        computerSkills: [],
        languageSkills: [],
      })
      //Structure des secteur
      secteur.map((sec) => {
        const filterCompetence = selectedTag.find((e) => sec.id === parseInt(e.tag.split("/")[2]));
        if (filterCompetence !== undefined) {
          setUserSkills(prev => ({
            ...prev, sectorialSkills: [...prev.sectorialSkills, {
              tag: filterCompetence.tag,
              forCard: filterCompetence.forCard,
              level: filterCompetence.level,
              name: sec.name,
              type: "sectorialSkills"
            }]
          }))
        }
      })
      //Structure des fonction
      fonctionnelle.map((sec) => {
        const filterCompetence = selectedTag.find((e) => sec.id === parseInt(e.tag.split("/")[2]));
        if (filterCompetence !== undefined) {
          setUserSkills(prev => ({
            ...prev, fonctionalSkills: [...prev.fonctionalSkills, {
              tag: filterCompetence.tag,
              forCard: filterCompetence.forCard,
              level: filterCompetence.level,
              name: sec.name,
              type: "fonctionalSkills"
            }]
          }))
        }
      })
      //Structure des informatique
      informatique.map((sec) => {
        const filterCompetence = selectedTag.find((e) => sec.id === parseInt(e.tag.split("/")[2]));
        if (filterCompetence !== undefined) {
          setUserSkills(prev => ({
            ...prev, computerSkills: [...prev.computerSkills, {
              tag: filterCompetence.tag,
              forCard: filterCompetence.forCard,
              level: filterCompetence.level,
              name: sec.name,
              type: "computerSkills"
            }]
          }))
        }
      })
      //Structure des langue
      linguistique.map((sec) => {
        const filterCompetence = selectedTag.find((e) => sec.id === parseInt(e.tag.split("/")[2]));
        if (filterCompetence !== undefined) {
          setUserSkills(prev => ({
            ...prev, languageSkills: [...prev.languageSkills, {
              tag: filterCompetence.tag,
              forCard: filterCompetence.forCard,
              level: filterCompetence.level,
              name: sec.name,
              type: "languageSkills"
            }]
          }))
        }
      })
    } catch (error) {
    }
  };


  const handleChange = (e) => {
    if (selectedTag.filter(item => item.tag === `api/tags/${e.target.value}`).length === 0) {
      if (userSkills[selectedSignet].length < 10) {
        setSelectedTag(prev => [...prev, {
          user: `api/users/${userInformation.id}`,
          tag: `api/tags/${e.target.value}`,
          level: 1
        }])
      }
    } else {
      setSelectedTag(prevState => prevState.filter(el => el.tag !== `api/tags/${e.target.value}`))
    }
    setSearchField("")
  }

  function handleLevel(e, level) {
    let newSelectedTag = [...selectedTag];
    const index = newSelectedTag.findIndex(item => item.tag === `api/tags/${e}`);
    newSelectedTag[index].level = level
    setSelectedTag(newSelectedTag)

    // setSelectedTag(prevState => prevState.filter(el => el.tag !== `api/tags/${e}`))
    // setSelectedTag(prev => [...prev, {
    //   user: `api/users/${userInformation.id}`,
    //   tag: `api/tags/${e}`,
    //   level: level
    // }])
  }

  useEffect(() => {
    getSkills()
  }, [selectedTag])


  useEffect(() => {
    if (userInformation) {
      if (userInformation.tags) {
        let newState = []
        userInformation.tags.forEach(element => {
          newState.push({
            user: `api/users/${userInformation.id}`,
            tag: `api/tags/${element.tag.id}`,
            level: element.level,
            forCard: element.for_card
          })
        })
        setSelectedTag(newState)
      }
    }
  }, [userInformation])

  function handleFavTag(usertag) {
    let newSelectedTag = [...selectedTag];
    const index = newSelectedTag.findIndex(item => item.tag === usertag.tag);
    if (usertag.forCard) {
      newSelectedTag[index].forCard = false
      setSelectedTag(newSelectedTag)
    } else {
      if (newSelectedTag.filter(item => item.forCard === true).length < 4) {
        newSelectedTag[index].forCard = true
        setSelectedTag(newSelectedTag)
      }
    }
  }




  return (
    <div className='bg-white p-4 overflow-auto border'>
      <div className='flex gap-4 mb-3 border-b pb-2 flex-wrap'>
        <p className={`group hover:bg-[#F3F7FF] hover:text-[#2563EB] ${selectedSignet === "sectorialSkills" ? "text-[#2563EB] bg-[#F3F7FF] border" : "border text-[#9C9B9F]"}  text-sm font-medium rounded p-2.5 cursor-pointer`} onClick={() => handleSignet("sectorialSkills")}>
          {/* <span className={`group-hover:text-white group-hover:bg-[#2563EB] ${selectedSignet === "sectorialSkills" ? "text-[#FFF] bg-[#2563EB]"  : " text-[#9C9B9F]"} py-1 px-2 bg-[#F7F9FC] rounded text-[#9C9B9F] mr-2`}>2</span> */}
          Sectorielles
        </p>
        <p className={`group hover:bg-[#F3F7FF] hover:text-[#2563EB] ${selectedSignet === "fonctionalSkills" ? "text-[#2563EB] bg-[#F3F7FF] border" : "border text-[#9C9B9F]"}  text-sm font-medium rounded p-2.5 cursor-pointer`} onClick={() => handleSignet("fonctionalSkills")}>
          {/* <span className={`group-hover:text-white group-hover:bg-[#2563EB] ${selectedSignet === "fonctionalSkills" ? "text-[#FFF] bg-[#2563EB]"  : " text-[#9C9B9F]"} py-1 px-2 bg-[#F7F9FC] rounded text-[#9C9B9F] mr-2`}>1</span> */}
          Fonctionnelles
        </p>
        <p className={`group hover:bg-[#F3F7FF] hover:text-[#2563EB] ${selectedSignet === "computerSkills" ? "text-[#2563EB] bg-[#F3F7FF] border" : "border text-[#9C9B9F]"}  text-sm font-medium rounded p-2.5 cursor-pointer`} onClick={() => handleSignet("computerSkills")}>
          {/* <span className={`group-hover:text-white group-hover:bg-[#2563EB] ${selectedSignet === "computerSkills" ? "text-[#FFF] bg-[#2563EB]"  : " text-[#9C9B9F]"} py-1 px-2 bg-[#F7F9FC] rounded text-[#9C9B9F] mr-2`}>6</span> */}
          Informatiques
        </p>
        <p className={`group hover:bg-[#F3F7FF] hover:text-[#2563EB] ${selectedSignet === "languageSkills" ? "text-[#2563EB] bg-[#F3F7FF] border" : "border text-[#9C9B9F]"}  text-sm font-medium rounded p-2.5 cursor-pointer`} onClick={() => handleSignet("languageSkills")}>
          Linguistiques
        </p>
        <div className='w-full'>
          <input placeholder='Recherche' name='recherche' id='recherche' className='py-2 px-2 ring-1 ring-gray-300 border-lightblue rounded-md w-full outline-none focus:ring-[#2563EB] focus:ring-2' type="text" value={searchField} onChange={(e) => setSearchField(e.target.value)} />
        </div>
      </div>
      <h5 className='text-lg flex gap-3 mb-2'>
        <Badge mode="info" name="min : 1" />
        <p className='w-fit text-sm text-black bg-[#E2E2E2] px-[5px] rounded-lg'>Compte : {userSkills[selectedSignet].length}</p>
        <Badge mode="warning" name="max : 10" />
        <p className='w-fit text-sm text-black  px-[5px] rounded-lg'> 1 _ : Connaissance </p>
        <p className='w-fit text-sm text-black  px-[5px] rounded-lg'> 2 _ : Pratique </p>
        <p className='w-fit text-sm text-black  px-[5px] rounded-lg'> 3 _ : Maitrise </p>
        <p className='w-fit text-sm text-black  px-[5px] rounded-lg'> 4 _ : Expert </p>
      </h5>
      <div className='max-w-[800px] gap-4'>
        <div className='flex flex-col gap-4'>
          <div className='items-start flex gap-1 flex-wrap '>
            {filters ?
              skills[selectedSignet].filter(x => removeAccents(x.name.toLowerCase().replace(/\/ /g, '')).includes(removeAccents(searchField.toLowerCase().replace(/\/ /g, '')))).map((el, key) => (
                <Checkbox
                  tagLevel={selectedSignet}
                  key={el.id}
                  id={el.id}
                  onChange={(e) => handleChange(e)}
                  handleLevel={handleLevel}
                  checked={selectedTag.filter(item => item.tag === `api/tags/${el.id}`).length > 0}
                  level={selectedTag.filter(item => item.tag === `api/tags/${el.id}`).length > 0 ? selectedTag.filter(item => item.tag === `api/tags/${el.id}`)[0].level : 1}
                  name={el.name}
                  value={el.id}
                  label={el.name} />
              ))
              :
              null
            }
          </div>
          <div className='mt-10 max-w-[750px]'>
            <h2>Sélection de vos compétences*</h2>
            <p>Veuillez choisir les tags des compétences que vous souhaitez voir apparaitre sur votre vignette de la page LES PROFILS</p>
            <p className='flex gap-3'>
              <Badge mode="info" name="min : 1" />
              <Badge mode="warning" name="max : 4" />
            </p>
          </div>
          <div className="flex gap-1 items-center flex-wrap max-w-[750px]">
            {userSkills && (userSkills.computerSkills.length === 0 &&
              userSkills.fonctionalSkills.length === 0 &&
              userSkills.languageSkills.length === 0 &&
              userSkills.sectorialSkills.length === 0) ? <div className='italic text-sm'>
              Veuillez sélectionner vos compétences au-dessus.
            </div>
              :
              Object.keys(userSkills).map(val => {
                return userSkills[val].map(element => {
                  return <TagForCard forCard={element.forCard} level={element.level} usertag={element} type={element.type} name={element.name} handleFavTag={handleFavTag} />
                });
              })
            }
          </div>
          <div className='flex justify-end'>
            <PrimaryButton onClick={() => onSubmit()} text={"Sauvegarder"} isLoading={isLoading} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PersonalSkills