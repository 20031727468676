import { useState, useEffect } from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'
import PrimaryButton from 'Components/Buttons/PrimaryButton'
import SecondaryButton from 'Components/Buttons/SecondaryButton'
import CurrencyInput from 'Components/Forms/CurrencyInput/CurrencyInput'

function Salary() {

    const [data, setData] = useOutletContext()
    const [formIsNotValid, setFormIsNotValid] = useState(true)

    const handleJtm = (e) => {
        let newData = { ...data }
        newData.jtm = e.target.value
        setData(newData)
    }

    const handleSalary = (e) => {
        let newData = { ...data }
        newData.salaire = e.target.value
        setData(newData)
    }

    const navigate = useNavigate()

    const next = () => {
        navigate("/parcours-inscription/formations")

    }

    const prev = () => {
        navigate("/parcours-inscription/competences")

    }


    const checkForm = () => {
        if (data.workExperience.includes("Mission") && data.jtm === "") {
            setFormIsNotValid(true)
            return
        }

        if ((data.workExperience.includes("CDD") || data.workExperience.includes("CDI") || data.workExperience.includes("Alternance")) && data.salaire === "") {
            setFormIsNotValid(true)
            return
        }

        setFormIsNotValid(false)



    }


    useEffect(() => {
        checkForm()
    }, [data])


    useEffect(() => {
        if (data.workExperience.length === 1 && data.workExperience.includes("Stage")) {
            navigate("/parcours-inscription/formations")
        }

        if (data.workExperience.length === 0) {
            navigate("/parcours-inscription")
        }
    })


    return (
        <div className='flex flex-col my-4 p-6 rounded bg-white gap-6'>
            <div>
                <h2>Prétentions</h2>
            </div>
            {
                data.workExperience.includes("CDD") || data.workExperience.includes("CDI") || data.workExperience.includes("Alternance") ?
                    <div>
                        <h5 className='text-3xl mb-2'>Salaire *</h5>
                        <p className=''>Indiquer vos prétentions salariales (rémunération brute annuelle)</p>
                        <CurrencyInput onChange={handleSalary} value={data.salaire} icon={"€"} />
                    </div>
                    :
                    null
            }
            {
                data.workExperience.includes("Mission") ?
                    <div>
                        <h5 className='text-3xl mb-2'>Taux journalier moyen *</h5>
                        <p className='text-base mb-[25px]'>Indiquez votre taux journalier moyen</p>
                        <CurrencyInput onChange={handleJtm} value={data.jtm} icon={"€"} />
                    </div>
                    :
                    null
            }
            <div className='flex justify-between min-w-[750px]'>
                <SecondaryButton onClick={prev} text={"précédent"} />
                <PrimaryButton disabled={formIsNotValid} onClick={next} text={"suivant"} />
            </div>
        </div>
    )
}

export default Salary