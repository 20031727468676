import React, { useState, useEffect, useContext } from 'react'
import { useNavigate } from "react-router-dom";
import { FaCalendarWeek } from "react-icons/fa";
import { GoClockFill } from "react-icons/go";
import { RxCross2 } from "react-icons/rx";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { FaHandshakeSimple } from "react-icons/fa6";
import { FaHandshakeSimpleSlash } from "react-icons/fa6";
import axios from "axios";
import cookie from "js-cookie";
import { isClient, isUser } from "utils/roles";
import { AuthContext } from "Context/AuthProvider";
import Avenir from "assets/img/missdata/Homme bureau téléphone.png";
import Homme_calendrier from "assets/img/missdata/Homme_calendrier.png";
import Homme_puzzle_rouages from "assets/img/missdata/Homme_puzzle_rouages.png";
import Hommes_poignée_de_main_bureau from "assets/img/missdata/Hommes_poignée_de_main_bureau.png";
import Hommes_poigneee_de_main from "assets/img/missdata/Hommes_poigneee_de_main.png";
import Femme_profil_refuse from "assets/img/missdata/Femme_profil_refuse.png";

function Entretien() {
  const [data, setData] = useState([]);
  const [display, setDisplay] = useState({ "avenir": true, "planifier": true, "annuler": true, "attente": true, "retenu": true, "refuser": true })
  const token = cookie.get("token");
  const navigate = useNavigate()
  const { value } = useContext(AuthContext);

  const formatTime = (date) => {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");
    return `${formattedHours}:${formattedMinutes}`;
  };

  function dislayClientRole(roles) {
    switch (roles[0]) {
      case "Cabinet-conseil":
        return "un cabinet de conseil"
      case "Cabinet-daudit-dexpertise-comptable":
        return "un cabinet d'audit et/ou d'expertise comptable"
      case "entreprise":
        return "une entreprise"
      default:
        return "client"
    }
  }

  function separeAppointment(values) {
    let array = { "avenir": [], "planifier": [], "annuler": [], "attente": [], "retenu": [], "refuser": [] }
    var next7day = new Date();
    next7day = next7day.setDate(next7day.getDate() + 7)
    values.forEach(value => {
      if (new Date(value.date_choice) > new Date()) {
        // Les entretiens à planifier (MAX 8)
        if (value.status === "planifier") {
          array.planifier.length < 8 && array.planifier.push(value);
        }
        // Les entretiens annuler (MAX 8)
        // Les entretien à venir de la semaine (MAX 8)
        else if (new Date(value.date_choice) > new Date() && new Date(value.date_choice) <= new Date(next7day) && value.status === "programmés") {
          array.avenir.length < 8 && array.avenir.push(value)
        }
      }
      // Les entretiens remporter en attente de réponse (MAX 8)
      if (value.status === "réaliser") {
        array.attente.length < 8 && array.attente.push(value);
      } else if (value.status === "archiver_win") {
        array.retenu.length < 8 && array.retenu.push(value);
      } else if (value.status === "archiver") {
        array.refuser.length < 8 && array.refuser.push(value);
      } else if (value.status === "cancel") {
        array.annuler.length < 8 && array.annuler.push(value);
      }
    });
    return array;
  }

  const getAppointment = () => {
    const type = isUser(value.user) ? "user" : isClient(value.user) ? "client" : null;
    if (type === null) {
      // Si le type n'est pas définie, on ne fait pas de request
      return
    }
    axios
      .get(process.env.REACT_APP_API + `${type}/entretien`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setData(separeAppointment(response.data))
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    getAppointment();
  }, [value]);

  function selectTypeAppointment(e) {
    if (e.target.value === "Tout les entretiens") {
      setDisplay({ "avenir": true, "planifier": true, "annuler": true, "attente": true, "retenu": true, "refuser": true })
    } else {
      setDisplay({ [e.target.value]: true })
    }
  }

  return (
    <>
      <div className='mt-4'>
        <div className='flex flex-row justify-between my-4'>
          <h3>Mes entretiens</h3>
          <select onChange={selectTypeAppointment} id="Entretien" class="bg-white border border-middleblue text-middleblue text-sm rounded-lg focus:ring-middleblue focus:border-middleblue block w-fit px-2">
            <option selected>Tout les entretiens</option>
            <option value="avenir">A venir</option>
            <option value="planifier">A Planifier</option>
            <option value="annuler">Annuler</option>
            <option value="attente">Remporter</option>
            <option value="retenu">Retenu</option>
            <option value="refuser">Refuser</option>
          </select>
        </div>
        <div className='grid grid-cols-1 lg:grid-cols-2 gap-4 '>

          {display.avenir &&
            <div>
              <h3>Entretiens programmés cette semaine</h3>
              <div className='border-gray-200 border-2 rounded p-4'>
                {data.avenir !== undefined &&
                  data.avenir.length !== 0 ?
                  data.avenir.map((appointment) => (
                    <div className="bg-[#EBF4FF] hover:bg-[#D6E9FF] rounded w-fit my-2 ">
                      <div className="flex flex-col my-2">
                        <p onClick={() => navigate(`/mon-espace/mes-entretiens/${appointment.id}`)} className="flex flex-row text-sm items-center gap-2 px-2 hover:cursor-pointer font-bold">
                          <FaCalendarWeek className='text-[#2563EB]' />
                          <div>
                            {
                              new Date(appointment.date_choice).toLocaleDateString("fr-FR", {
                                weekday: 'long',
                              })
                              + " " +
                              formatTime(new Date(appointment.date_choice))
                            }
                            - Entretien avec
                            {appointment.id_client && " " + appointment.id_client.company + " "}
                            {appointment.id_user && " " + appointment.id_user.first_name + " " + appointment.id_user.last_name + " "}
                            {appointment.apply && " suite à votre candidature"}
                          </div>
                        </p>
                      </div>
                    </div>
                  )) :
                  <div className="flex flex-row items-center justify-around my-2">
                    <div className='w-1/4'>
                      <img
                        className="w-auto h-[150px] opacity-70"
                        src={Avenir}
                        alt="MyFinPlace.com"

                      />
                    </div>
                    <div >
                      <h3 className=" px-2 mx-2 font-bold">Aucun entretien a venir pour le moment</h3>
                    </div>
                  </div>}
              </div>
            </div>
          }

          {display.planifier &&
            <div>
              <h3>Entretiens à planifier </h3>
              <div className='border-gray-200 border-2 rounded p-4'>
                {data.planifier !== undefined &&
                  data.planifier.length !== 0 ?
                  data.planifier.map((appointment) => (
                    <div className="bg-[#FDF5E1] hover:bg-[#F9E3AE] rounded w-fit my-2 ">
                      <div className="flex flex-col my-2 ">
                        <p onClick={() => navigate(`/mon-espace/mes-entretiens/${appointment.id}`)} className="flex flex-row text-sm items-center gap-2 px-2 hover:cursor-pointer font-bold">
                          <GoClockFill className='text-[#FFA500]' />
                          <div>
                            Entretien a planifier avec
                            {appointment.id_client && " " + dislayClientRole(appointment.id_client.roles) + " "}
                            {appointment.id_user && " un " + appointment.id_user.roles[0] + " "}
                            {appointment.apply && " suite à votre candidature"}
                          </div>
                        </p>
                      </div>
                    </div>
                  )) :
                  <div className="flex flex-row items-center justify-around my-2">
                    <div className='w-1/3'>
                      <img className="w-auto h-[150px] opacity-70" src={Homme_calendrier} alt="MyFinPlace.com" />
                    </div>
                    <div >
                      <h3 className=" px-2 mx-2 font-bold">Aucun entretien à planifier pour le moment</h3>
                    </div>
                  </div>
                }
              </div>
            </div>
          }

          {display.annuler &&
            <div>
              <h3>Entretiens annulés</h3>
              <div className='border-gray-200 border-2 rounded p-4'>
                {data.annuler !== undefined &&
                  data.annuler.length !== 0 ?
                  data.annuler.map((appointment) => (
                    <div className="bg-[#FCDCDC] hover:bg-[#F4BCBC] hover:cursor-pointer rounded w-fit my-2 ">
                      <div className="flex flex-col my-2 ">
                        <p onClick={() => navigate(`/mon-espace/mes-entretiens/${appointment.id}`)} className="flex flex-row text-sm items-center gap-2 px-2 font-bold"><span className='bg-[#FF0000] text-white'><RxCross2 /> </span>
                          Entretien annuler avec
                          {appointment.id_client && " " + dislayClientRole(appointment.id_client.roles) + " "}
                          {appointment.id_user && " un " + appointment.id_user.roles[0] + " "}
                          le
                          {" " + new Date(appointment.date_choice).toLocaleDateString("fr-FR", {
                            weekday: "long",
                            day: "2-digit",
                            month: "long"
                          })
                            + " à " +
                            formatTime(new Date(appointment.date_choice))
                          }
                          {appointment.apply && " suite à votre candidature"}
                        </p>
                      </div>
                    </div>
                  )) :
                  <div className="flex flex-row items-center justify-around my-2">
                    <div className='w-1/3'>
                      <img className="w-auto h-[150px] opacity-70" src={Homme_puzzle_rouages} alt="MyFinPlace.com" />
                    </div>
                    <div >
                      <h3 className=" px-2 mx-2 font-bold">Aucun entretien annulé pour le moment</h3>
                    </div>
                  </div>
                }
              </div>
            </div>
          }

          {display.attente &&
            <div>
              <h3>Entretiens passés</h3>
              <div className='border-gray-200 border-2 rounded p-4'>
                {data.attente !== undefined &&
                  data.attente.length !== 0 ?
                  data.attente.map((appointment) => (
                    <div className="bg-[#DCFCE6] hover:bg-[#BBF9CE] rounded w-fit my-2 ">
                      <div className="flex flex-col my-2 ">
                        <p onClick={() => navigate(`/mon-espace/mes-entretiens/${appointment.id}`)} className="flex flex-row text-sm items-center gap-2 px-2 hover:cursor-pointer font-bold"><span className=' text-[#00C455]'><IoIosCheckmarkCircle /> </span>
                          Entretien terminer avec
                          {appointment.id_client && " " + appointment.id_client.company + " "}
                          {appointment.id_user && " " + appointment.id_user.first_name + " " + appointment.id_user.last_name + " "}
                          le
                          {" " + new Date(appointment.date_choice).toLocaleDateString("fr-FR", {
                            weekday: "long",
                            day: "2-digit",
                            month: "long"
                          })
                            + " à " +
                            formatTime(new Date(appointment.date_choice))
                          }
                          {appointment.apply && " suite à votre candidature"}
                        </p>
                      </div>
                    </div>
                  )) :
                  <div className="flex flex-row items-center justify-around my-2">
                    <div className='w-1/3'>
                      <img className="w-auto h-[150px] opacity-70" src={Hommes_poignée_de_main_bureau} alt="MyFinPlace.com" />
                    </div>
                    <div >
                      <h3 className=" px-2 mx-2 font-bold">Aucun entretien passé pour le moment</h3>
                    </div>
                  </div>}
              </div>
            </div>
          }

          {display.retenu &&
            <div>
              <h3>Candidatures retenues</h3>
              <div className='border-gray-200 border-2 rounded p-4'>
                {data.retenu !== undefined &&
                  data.retenu.length !== 0 ?
                  data.retenu.map((appointment) => (
                    <div onClick={() => navigate(`/mon-espace/mes-entretiens/${appointment.id}`)} className="flex gap-2 items-center bg-[#DCFCE6] hover:bg-[#BBF9CE] hover:cursor-pointer rounded w-fit my-2 px-2 text-sm font-bold">
                      <span className='text-[#00C455]'>
                        <FaHandshakeSimple />
                      </span>
                      <span>
                        Le {" " + new Date(appointment.date_choice).toLocaleDateString("fr-FR", {
                          weekday: "long",
                          day: "2-digit",
                          month: "long"
                        })
                          + " à " +
                          formatTime(new Date(appointment.date_choice))
                        }
                        {appointment.id_client && ", votre profil a été retenu par " + appointment.id_client.company}
                        {appointment.id_user && ", vous avez retenu le profil " + appointment.id_user.first_name + " " + appointment.id_user.last_name + " "}
                      </span>
                    </div>
                  )) :
                  <div className="flex flex-row items-center justify-around my-2">
                    <div className='w-1/3'>
                      <img className="w-auto h-[150px] opacity-70" src={Hommes_poigneee_de_main} alt="MyFinPlace.com" />
                    </div>
                    <div >
                      <h3 className=" px-2 mx-2 font-bold">Aucune candidature retenue pour le moment</h3>
                    </div>
                  </div>}
              </div>
            </div>
          }

          {display.refuser &&
            <div>
              <h3>Candidatures non retenues</h3>
              <div className='border-gray-200 border-2 rounded p-4'>
                {data.refuser !== undefined &&
                  data.refuser.length !== 0 ?
                  data.refuser.map((appointment) => (
                    <div onClick={() => navigate(`/mon-espace/mes-entretiens/${appointment.id}`)} className="flex gap-2 items-center bg-[#FCDCDC] hover:bg-[#F4BCBC] hover:cursor-pointer rounded w-fit my-2 px-2 text-sm font-bold">
                      <span className='text-[#FF0000]'>
                        <FaHandshakeSimpleSlash />
                      </span>
                      <span>
                        Le {" " + new Date(appointment.date_choice).toLocaleDateString("fr-FR", {
                          weekday: "long",
                          day: "2-digit",
                          month: "long"
                        })
                          + " à " +
                          formatTime(new Date(appointment.date_choice))
                        }
                        {appointment.id_client && ", votre profil a été refusé par " + appointment.id_client.company}
                        {appointment.id_user && ", vous avez refusé le profil " + appointment.id_user.first_name + " " + appointment.id_user.last_name + " "}
                      </span>
                    </div>
                  )) :
                  <div className="flex flex-row items-center justify-around my-2">
                    <div className='w-1/3'>
                      <img className="w-auto h-[150px] opacity-70" src={Femme_profil_refuse} alt="MyFinPlace.com" />
                    </div>
                    <div >
                      <h3 className=" px-2 mx-2 font-bold">Aucune candidature refusée pour le moment</h3>
                    </div>
                  </div>}
              </div>
            </div>
          }
        </div>
      </div>
    </>
  )
}

export default Entretien