import { useState, useEffect } from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'
import PrimaryButton from 'Components/Buttons/PrimaryButton'
import SecondaryButton from 'Components/Buttons/SecondaryButton'
import RadioButton from 'Components/Forms/RadioButton/RadioButton'
import Checkbox from 'Components/Forms/CheckBox/Checkbox'
import Location from "assets/svg/Location";
import Mobile from "assets/svg/Mobile";
import Badge from 'Components/Badge/Badge';
import { LuLoader2 } from "react-icons/lu";
const accents = require('remove-accents');




export default function Mobility() {

    const navigate = useNavigate()
    const [data, setData] = useOutletContext()


    const next = () => {
        navigate("/parcours-inscription/competences")
    }

    const prev = () => navigate("/parcours-inscription/type-contrat")

    const [mobilityTags, setMobilityTags] = useState([])
    const [mobilities, setMobilities] = useState([])
    const [mobilityInput, setMobilityInput] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [formIsNotValid, setFormIsNotValid] = useState(true)


    const handleAvaibility = (e) => {
        console.log(e.target.value)
        let newData = { ...data };
        newData.disponibility = e.target.value
        if (e.target.value !== 'Date connue') {
            newData.availabilityDate = ""
        }
        setData(newData)
    }

    function handleRemote(e) {
        let newData = { ...data };
        newData.remote = e.target.value
        setData(newData)
    }

    function handleWorkTime(e) {
        let newData = { ...data }
        const targetValue = e.target.value
        if (newData.workTime.includes(targetValue)) {
            newData.workTime = newData.workTime.filter(item => item !== targetValue)
        } else {
            newData.workTime = [...newData.workTime, targetValue]
        }
        setData(newData)
    }

    const handleAvaibilityDate = (e) => {
        let newData = { ...data };
        newData.availabilityDate = e.target.value
        setData(newData)

    }

    const getTags = () => {
        const options = { method: 'GET' };
        fetch(process.env.REACT_APP_API + "filtres", options)
            .then(res => res.json())
            .then(data => {
                setMobilityTags(data.localisation)
            })
            .catch(err => console.log(err))
            .finally(() => {
                setIsLoading(false)
            })
    }

    const handleLocalisation = (e) => {
        let newData = { ...data };
        newData["department"] = e.target.value
        setData(newData)
    }

    const handleInput = (e) => {
        setMobilityInput(e.target.value);
        const filter = mobilityTags.filter(word => accents.remove(word.nom.replace(/[-']/g, " ").toLowerCase()) === accents.remove(e.target.value.replace(/[-']/g, " ").toLowerCase()));

        if (filter.length > 0) {
            if (!data.localisation.includes(filter[0].nom)) {
                let newData = { ...data }
                newData.localisation.push(filter[0].nom)
                setData(newData)
            }
            setMobilityInput("");
        }
    }

    function deleteMobility(value) {
        setMobilities(mobilities.filter(item => item !== value))
        let newData = { ...data }
        let removedData = newData.localisation.filter(localisation => localisation != value)
        newData.localisation = removedData
        setData(newData)
    }

    useEffect(() => {
        getTags()
    }, [])

    const checkForm = () => {

        if (data.department.length == 0) {
            setFormIsNotValid(true)
            return;
        }

        if (data.disponibility === "Date connue" && data.availabilityDate === "") {
            setFormIsNotValid(true)
            return;
        }

        if (data.disponibility === "") {
            setFormIsNotValid(true)
            return;
        }

        if (data.remote === "") {
            setFormIsNotValid(true)
            return;
        }

        if (data.workTime.length === 0) {
            setFormIsNotValid(true)
            return;
        }

        setFormIsNotValid(false)
    }



    useEffect(() => {
        checkForm()
    }, [data])



    return (
        <div className={`flex flex-col my-4 p-6 rounded ${isLoading ? "" : "bg-white"} gap-6`}>
            {
                isLoading ?
                    <div className="text-[#2563EB] mt-8">
                        <LuLoader2 size={48} className='animate-spin' />
                    </div>
                    :
                    <>
                        <div>
                            <h2>Modalité</h2>
                        </div>
                        <div>
                            <div>
                                <h5 className='text-3xl mb-2'>Localisation *</h5>
                                <p className='text-base mb-1'>Dans quelle région habitez vous ?</p>
                                <div className='p-2 border flex items-center gap-2 rounded-[3px] border-[#0e0033]'>
                                    <Location color={"#263238"} width={20} height={16} />
                                    <select value={data.department} onChange={handleLocalisation} className='hover:cursor-pointer w-full outline-none bg-white' type="text" name="regions" list="localisation">
                                        <option className='hidden'></option>
                                        {
                                            mobilityTags ?
                                                mobilityTags.filter(r => !data.localisation.includes(r.nom)).map(r => <option className='w-full' key={r.code} value={r.nom} >{r.nom}</option>)
                                                :
                                                null
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div>
                                <div className='flex items-center mb-2 gap-2'>
                                    <h5 className='text-3xl'>Mobilité </h5>
                                    <Badge mode="danger" name={"Max : 5"} />
                                    <Badge mode="success" name={"Optionnelle"} />
                                </div>
                                <p className='text-base mb-1'>Dans quelle région seriez vous prêt à travailler (autre que votre région d'habitation) ?</p>
                                <div className='p-2 border flex items-center gap-2 rounded-[3px] border-[#0e0033]'>
                                    <Mobile color={"#263238"} width={20} height={16} />
                                    <select disabled={data.localisation.length >= 5 ? true : false} value={mobilityInput} onChange={(e) => handleInput(e)} className='hover:cursor-pointer w-full outline-none bg-white' type="text" name="regions" list="mobility">
                                        <option className='hidden'></option>
                                        {
                                            mobilityTags ?
                                                mobilityTags.filter(r => r.nom !== data.department).map(r => <option className='w-full' key={r.code} value={r.nom} >{r.nom}</option>)

                                                :
                                                null
                                        }
                                    </select>
                                </div>
                                <div className='flex mt-2 gap-2 flex-wrap max-w-[500px]'>
                                    {
                                        data.localisation.map((item, key) =>
                                            <div key={key} className='flex items-center gap-3 border rounded-[5px] border-[#5B9ECF] text-white bg-[#5B9ECF] px-[20px] py-[10px]'>
                                                {item}
                                                <span>
                                                    <div onClick={() => deleteMobility(item)} className='flex items-center justify-center hover:cursor-pointer w-[10px]'>
                                                        <div className='absolute w-[1.5px] h-[10.3px] bg-white rotate-45'></div>
                                                        <div className='w-[1.5px] h-[10.3px] bg-white rotate-[135deg]'></div>
                                                    </div>
                                                </span>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        <div>
                            <div>
                                <h5 className='text-3xl mb-2'>Présentiel / télétravail *</h5>
                                <p className='text-base mb-[25px]'>Comment souhaitez vous organiser votre temps de travail ? ?</p>
                                <div className='flex flex-col gap-2'>
                                    <div className='flex flex-col md:flex-row gap-1'>
                                        <RadioButton onChange={handleRemote} value={"Présentiel"} name={"Présentiel"} label={"Présentiel"} checked={data.remote == "Présentiel"} />
                                        <RadioButton onChange={handleRemote} value={"Hybride"} name={"Hybride"} label={"Hybride"} checked={data.remote == "Hybride"} />
                                        <RadioButton onChange={handleRemote} value={"Télétravail"} name={"Télétravail"} label={"Télétravail"} checked={data.remote == "Télétravail"} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div>
                                <div className='flex flex-col sm:flex-row items-center gap-1 mb-2'>
                                    <h5 className='text-3xl'>Temps plein / partiel *</h5>
                                    <Badge mode={"info"} name={"Plusieurs choix possible"} />
                                </div>
                                <div className='flex flex-col gap-2'>
                                    <div className='flex flex-col md:flex-row gap-1'>
                                        {["Temps plein", "Temps partiel"].map((value, key) => (
                                            <Checkbox
                                                onChange={handleWorkTime}
                                                key={key}
                                                id={value}
                                                label={value}
                                                name={value}
                                                value={value}
                                                checked={data.workTime.includes(`${value}`)}
                                                defaultDesign={true}
                                            />))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div>
                                <h5 className='text-3xl mb-2'>Disponibilité *</h5>
                                <p className='text-base mb-[25px]'>Quelle est votre disponibilité ?</p>
                                <div className='flex flex-col gap-2'>
                                    <div className='flex flex-col md:flex-row md:items-center gap-2'>
                                        <RadioButton onChange={handleAvaibility} value={"Immédiate"} name={"availability"} label={"Immédiate"} checked={data.disponibility == "Immédiate"} />
                                        <RadioButton onChange={handleAvaibility} value={"Date connue"} name={"availability"} checked={data.disponibility == "Date connue"} label={"Date connue"} />
                                        <input type="date" value={data.availabilityDate} onChange={(e) => handleAvaibilityDate(e)} disabled={data.disponibility != "Date connue"} className={`${data.disponibility == "Date connue" ? "border border-[#0e0033] " : "border border-[#D2D2D2] text-[#D2D2D2]"} border rounded-[5px] py-[10px] px-[20px] cursor-pointer`} />
                                    </div>
                                    <div className='flex flex-col md:flex-row gap-1'>
                                        <RadioButton onChange={handleAvaibility} value={"Préavis de 1 mois"} name={"availability"} label={"Préavis de 1 mois"} checked={data.disponibility == "Préavis de 1 mois"} />
                                        <RadioButton onChange={handleAvaibility} value={"Préavis de 2 mois"} name={"availability"} label={"Préavis de 2 mois"} checked={data.disponibility == "Préavis de 2 mois"} />
                                        <RadioButton onChange={handleAvaibility} value={"Préavis de 3 mois"} name={"availability"} label={"Préavis de 3 mois"} checked={data.disponibility == "Préavis de 3 mois"} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex justify-between'>
                            <SecondaryButton onClick={prev} text={"précédent"} />
                            <PrimaryButton disabled={formIsNotValid} onClick={next} text={"suivant"} />
                        </div>
                    </>

            }
        </div>


    )
}
