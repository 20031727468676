import { useEffect, useState, useContext } from 'react';
import PrimaryButton from 'Components/Buttons/PrimaryButton'
import SecondaryButton from 'Components/Buttons/SecondaryButton'
import Wysiwig from "Components/wysiwig/wysiwig";
import { useForm } from 'react-hook-form'
import { isSmallerDate } from 'utils/dateUtils';
import Cookies from "js-cookie";
import axios from "axios";
import { AlertMessageContext } from 'Context/AlertMessageProvider';
import { MonthPicker, HandleShowMonth } from 'Components/Forms/MonthPicker/MonthPicker';
import { CiCalendar } from 'react-icons/ci';


function CreateOrEditFormationModal({ experience = null, test, setUserInformation, close, getPourcentage }) {
    const maxCaracteres = 1500;
    const [description, setDescription] = useState("")
    const { alert } = useContext(AlertMessageContext);
    const [isLoading, setIsLoading] = useState(false)
    const token = Cookies.get("token");
    const { register, handleSubmit, setValue, setError, formState: { errors, isDirty } } = useForm({
        mode: "onSubmit"
    });

    const handleDescription = (e) => {
        setDescription(e);
    };
    const onSubmit = async (formData) => {
        setIsLoading(true);
        const data = {
            ...formData,
            description,
            bac: parseInt(formData.bac),
            dateStart: formData.dateStart.slice(0, 3) + "01/" + formData.dateStart.slice(4, 7),
            dateEnd: formData.dateEnd.slice(0, 3) + "01/" + formData.dateEnd.slice(4, 7)
        }
        let formation = null
        if (new Date(data.dateStart) >= new Date(data.dateEnd)) {
            setError("dateEnd", { type: "custom", message: "Votre date de fin ne peut pas être postérieure à votre date de début" })
            setIsLoading(false);
            return
        }
        if (data.description.length > maxCaracteres) {
            setIsLoading(false);
            return
        }
        try {
            if (experience) {
                formation = await editFormation(data)
                console.log(formation)
                setUserInformation(prev => (
                    {
                        ...prev,
                        formation: prev.formation.map(f => {
                            if (f.id == experience.id) {
                                return formation
                            } else {
                                return f
                            }
                        })
                    }
                ))
            } else {
                formation = await createFormation(data)
                setUserInformation(prev => ({ ...prev, formation: [formation, ...prev.formation] }))
                getPourcentage();
            }
            alert.successAlert(formation ? "Votre formation a été mise à jour." : "Votre formation a été ajoutée.");
            setIsLoading(false);
            close()
        } catch (error) {
            setIsLoading(false);
            console.log(error)
        }
    }


    const createFormation = async (data) => {
        try {
            const url = `${process.env.REACT_APP_API}user/formation/create`
            const header = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json"
                }
            }

            const res = await axios.post(url, data, header)
            console.log(res)
            const formation = {
                school: res.data.school,
                bac: res.data.bac,
                diplome: res.data.diplome,
                date_start: res.data.dateStart,
                date_end: res.data.dateEnd,
                description: res.data.description,
                id: res.data.id
            }

            return formation

        } catch (error) {
            throw error
        }
    }


    const editFormation = async (data) => {
        const url = `${process.env.REACT_APP_API}user/formation/modify/${experience.id}`
        const header = {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/merge-patch+json"
            }
        }

        const res = await axios.patch(url, data, header)

        const formation = {
            school: res.data.school,
            bac: res.data.bac,
            diplome: res.data.diplome,
            date_start: res.data.dateStart,
            date_end: res.data.dateEnd,
            description: res.data.description,
            id: res.data.id
        }

        return formation
    }


    useEffect(() => {
        if (experience) {
            setValue("school", experience.school)
            setValue("diplome", experience.diplome)
            const dateStart = experience.date_start.split('T')[0].split("-")
            setValue("dateStart", dateStart[1] + '/' + dateStart[0])
            const dateEnd = experience.date_end.split('T')[0].split("-")
            setValue("dateEnd", dateEnd[1] + '/' + dateEnd[0])
            setValue("bac", experience.bac)
            setDescription(experience.description)

        }
    }, [])

    function MonthMask(ev) {
        var text = ev.target.value
        text = text.replace(/[^0-9.]/g, '');
        if (text.length >= 2) {
            text = text.slice(0, 2) + "/" + text.slice(2);
        }
        if (text.length > 7) {
            text = text.slice(0, 7);
        }
        ev.target.value = text;
    }

    return (
        <div>
            <form action="" onSubmit={handleSubmit(onSubmit)}>
                <div className='mb-2'>
                    <div className='grid grid-cols-1 sm:grid-cols-6 gap-2 mb-4'>
                        <div className='sm:col-span-6'>
                            <label htmlFor="firstname" className='block font-medium leading-6 text-gray-900'>Nom de l'école*</label>
                            <input name='school' id='school' className='py-2 px-2 ring-1 ring-gray-300 border-lightblue rounded-md w-full outline-none focus:ring-[#2563EB] focus:ring-2' type="text" {...register('school', { required: "Ce champ est requis" })} />
                            {errors.school && (
                                <span className="text-red-500 text-xs font-bold mt-1">
                                    {errors.school.message}
                                </span>
                            )}
                        </div>
                        <div className='sm:col-span-6'>
                            <label htmlFor="diplome" className='block font-medium leading-6 text-gray-900'>Intitulé du diplome*</label>
                            <input name='diplome' id='diplome' className='py-2 px-2 ring-1 ring-gray-300 border-lightblue rounded-md w-full outline-none focus:ring-[#2563EB] focus:ring-2' type="text" {...register("diplome", { required: "Ce champ est requis" })} />
                            {errors.diplome && (
                                <span className="text-red-500 text-xs font-bold mt-1">
                                    {errors.diplome.message}
                                </span>
                            )}
                        </div>
                        <div className='sm:col-span-6'>
                            <label htmlFor="firstname" className='block font-medium leading-6 text-gray-900'>Niveau*</label>
                            <select id="bac" name="bac" className='w-full bg-white py-[10px] px-[10px] border-[1.5px] border-lightblue rounded-[3px] '{...register('bac', { required: "Ce champ est requis" })} >
                                <option value={0}>Certification</option>
                                <option value={1}>Bac + 1</option>
                                <option value="2">Bac + 2</option>
                                <option value="3">Bac + 3</option>
                                <option value="4">Bac + 4</option>
                                <option value="5">Bac + 5</option>
                                <option value="6">Bac + 6</option>
                                <option value="7">Bac + 7</option>
                                <option value="8">Bac + 8</option>
                            </select>
                            {errors.bac && (
                                <span className="text-red-500 text-xs font-bold mt-1">
                                    {errors.bac.message}
                                </span>
                            )}
                        </div>
                        <div className='sm:col-span-3'>
                            <label htmlFor="dateStart" className='block font-medium leading-6 text-gray-900'>Date de debut*</label>
                            <div className='relative'>
                                <input name='dateStart' id='dateStart' onInput={MonthMask} placeholder='MM/YYYY' className='py-2 px-2 ring-1 ring-gray-300 border-lightblue rounded-md w-full outline-none focus:ring-[#2563EB] focus:ring-2' type="text" pattern='[0-9]{2}/[0-9]{4}' {...register("dateStart", { required: "Ce champ est requis" })} />
                                <div id="CiCalendar1" className='absolute top-1 right-2'>
                                    <CiCalendar size={25} onClick={() => HandleShowMonth("1")} />
                                    <span className='hidden' id='MonthPicker1'>
                                        <MonthPicker setValue={setValue} labelValue="dateStart" idInput="dateStart" />
                                    </span>
                                </div>
                            </div>
                            {errors.dateStart && (
                                <span className="text-red-500 text-xs font-bold mt-1">
                                    {errors.dateStart.message}
                                </span>
                            )}
                        </div>
                        <div className='sm:col-span-3'>
                            <label htmlFor="dateEnd" className='block font-medium leading-6 text-gray-900'>Date de fin*</label>
                            <div className='relative'>
                                <input name='dateEnd' id='dateEnd' onInput={MonthMask} placeholder='MM/YYYY' className='py-2 px-2 ring-1 ring-gray-300 border-lightblue rounded-md w-full outline-none focus:ring-[#2563EB] focus:ring-2' type="text" pattern='[0-9]{2}/[0-9]{4}' {...register("dateEnd", { required: "Ce champ est requis" })} />
                                <div id="CiCalendar2" className='absolute top-1 right-2'>
                                    <CiCalendar size={25} onClick={() => HandleShowMonth("2")} />
                                    <span className='hidden' id='MonthPicker2'>
                                        <MonthPicker setValue={setValue} labelValue="dateEnd" idInput="dateEnd" />
                                    </span>
                                </div>
                            </div>
                            {errors.dateEnd && (
                                <span className="text-red-500 text-xs font-bold mt-1">
                                    {errors.dateEnd.message}
                                </span>
                            )}
                        </div>
                    </div>
                    <div>
                        <Wysiwig text={description} setText={handleDescription} />
                        {maxCaracteres &&
                            <p className={`text-right ${description.length > maxCaracteres && "text-red-500"}`}>
                                {description ? description.length : 0} / {maxCaracteres}
                            </p>
                        }
                    </div>

                </div>
                <div className='flex items-center justify-end gap-2'>
                    <SecondaryButton onClick={close} text={"Annuler"} />
                    <PrimaryButton type={"submit"} isLoading={isLoading} text={"Enregistrer"} />
                </div>
            </form>
        </div>
    )
}

export default CreateOrEditFormationModal