import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useOutletContext } from "react-router-dom";
import Input from '../../../../Components/Forms/Input/Input'
import RadioButton from 'Components/Forms/RadioButton/RadioButton'
import PrimaryButton from 'Components/Buttons/PrimaryButton'
import cookies from "js-cookie";
import { LuLoader2 } from "react-icons/lu";




function LastExperience() {

    const [data, setData] = useOutletContext()

    const navigate = useNavigate()
    const [experienceTags, setExperienceTags] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const limitPoste = 80;

    const getTags = () => {
        const options = { method: 'GET' };
        fetch(process.env.REACT_APP_API + "filtres", options)
            .then(res => res.json())
            .then(data => {
                setExperienceTags(data.timeExperience)
            })
            .catch(err => console.log(err))
            .finally(() => setIsLoading(false))
    }

    const nextPage = () => {
        if (data.titre.length >= limitPoste) {
            setIsLoading(false)
            return;
        }
        navigate("type-contrat")
    }

    const handleChange = (e) => {
        let newState = { ...data }
        newState[e.target.name] = e.target.value
        setData(newState)
    }

    useEffect(() => {
        getTags();
        window.scrollTo(0, 0)
    }, [])


    return (
        <div className={`flex flex-col my-4 p-6 rounded ${isLoading ? "" : "bg-white"} gap-6`}>
            {
                isLoading ?
                    <div className="text-[#2563EB] mt-8">
                        <LuLoader2 size={48} className='animate-spin' />
                    </div>
                    :
                    <>
                        <div>
                            <h2 className=''>Bienvenue parmi nous !</h2>
                            <h5 className='mb-6'>* Champ obligatoire</h5>
                        </div>


                        <div className='relative'>
                            <label htmlFor=""><h5>Quelle est votre fonction ? *</h5></label>
                            <Input name="titre" value={data.titre} onChange={handleChange} />
                            {data.titre && data.titre.length != null ?
                                <div className={`absolute right-0 text-right ${data.titre.length >= limitPoste && "text-red-500"}`}>{data.titre.length}/{limitPoste}</div>
                                : <div className="absolute right-0 text-right">0/80</div>
                            }
                        </div>
                        <p>* Tenir, réviser, surveiller, consolider, apprécier, redresser les comptabilités des entreprises est une mission réservée exclusivement aux professionnels inscrits auprès de l’Ordre des experts-comptables.
                            <br /> <b>Vous ne pourrez donc pas exercer une activité en comptabilité en tant que freelance. Vous pouvez par ailleurs exercer une fonction de consultant en finance.</b></p>
                        <div className='mt-6'>
                            <h5>Votre niveau d'experience *</h5>
                            <p className='mb-[25px]'>Quel est votre ancienneté professionnelle ? (hors stage et alternance) </p>
                            <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4'>
                                {
                                    experienceTags ?
                                        experienceTags.map((value, key) => (
                                            <RadioButton key={key} id={value.id} label={value.name} name={"timeExperience"} value={value.name} checked={data.timeExperience == value.name} onChange={handleChange} />
                                        )) :
                                        null
                                }
                            </div>
                        </div>
                        <div className='flex justify-end'>
                            <PrimaryButton disabled={data.titre.length <= 0 || data.timeExperience.length <= 0} onClick={nextPage} text={"suivant"} />
                        </div>
                    </>
            }
        </div>
    )
}

export default LastExperience