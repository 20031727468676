import DetailOffreTitle from "./DetailOffreTitle"
import OffreBadge from "Components/Badge/OffreBadge"

import { MdChecklist } from "react-icons/md";
import { FaFileContract } from "react-icons/fa";
import { FaBriefcase } from "react-icons/fa";
import { FaWallet } from "react-icons/fa";
import { VscRemoteExplorer } from "react-icons/vsc";
import { MdOutlineTimelapse } from "react-icons/md";


function Modality({ contract, interventionTypeTags, salary, jtm, remote, workTime, isComptable }) {
    function colorContrat(contrat) {
        switch (contrat) {
            case "Stage":
                return "bg-[#B3D8F2]"
            case "Alternance":
                return "bg-[#B3D8F2]"
            case "CDD":
                return "bg-[#B3E7FC]"
            case "CDI":
                return "bg-[#B3E7FC]"
            case "Mission":
                return "bg-[#7CC8FF]"
            default:
                break;
        }
    }

    return (
        <>
            <DetailOffreTitle title={"Modalités"} icon={<MdChecklist />} fontSize={"text-[1.5rem]"} />
            <div className="grid grid-cols-2 xl:grid-cols-3 gap-4">
                <div>
                    <DetailOffreTitle title={"Contrat recherché"} icon={<FaFileContract className="text-[#497EA6]" />} />
                    <div className="flex gap-2 flex-wrap">
                        {
                            contract.map((contract, key) => {
                                if (contract.name === "Mission" && isComptable) {
                                    return
                                }
                                return <small key={key} className={colorContrat(contract.name) + ` rounded py-[5.5px] px-3.5`}>
                                    {contract.name}
                                </small>
                            })
                        }
                        {!contract.length && <OffreBadge key={"contract_empty"} name={"Non définie"} />}
                    </div>
                </div>
                <div>
                    <DetailOffreTitle title={"Type d'intervention"} icon={<FaBriefcase className="text-[#497EA6]" />} />
                    <div className="flex gap-2 flex-wrap">
                        {
                            interventionTypeTags.map((tag, key) =>
                                <OffreBadge key={key} name={tag.name} />
                            )
                        }
                    </div>
                </div>
                <div>
                    <DetailOffreTitle title={"Prétentions"} icon={<FaWallet className="text-[#497EA6]" />} />
                    <div className="flex gap-1 items-center flex-wrap">
                        {salary && <OffreBadge key={"salary"} name={salary + " €"} />}
                        {jtm && !isComptable && <OffreBadge key={"jtm"} name={jtm + " TJM"} />}
                        {!salary && !jtm && <OffreBadge key={"salary_empty"} name={"Non définie"} />}
                    </div>
                </div>
                <div>
                    <DetailOffreTitle title={"Présentiel / télétravail"} icon={<VscRemoteExplorer className="text-[#497EA6]" />} />
                    <div className="flex gap-1 items-center flex-wrap">
                        {remote ? <OffreBadge key={remote} name={remote} /> : <OffreBadge key={"remote_empty"} name={"Non définie"} />}
                    </div>
                </div>
                <div>
                    <DetailOffreTitle title={"Temps plein / partiel"} icon={<MdOutlineTimelapse className="text-[#497EA6]" />} />
                    <div className="flex gap-1 items-center flex-wrap">
                        {workTime && workTime.length > 0 &&
                            workTime.map((type, key) =>
                                <OffreBadge key={remote} name={type} />
                            )}
                        {(!workTime || workTime.length === 0) && <OffreBadge key={"workTime_empty"} name={"Non définie"} />}
                    </div>
                </div>

            </div>
        </>
    )
}

export default Modality