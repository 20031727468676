import { FieldGuesser, ListGuesser, EditGuesser, InputGuesser, ShowGuesser, CreateGuesser } from "@api-platform/admin";
import { usePermissions, DeleteWithConfirmButton, useRecordContext, SelectInput, TextInput, downloadCSV } from "react-admin";
import jsonExport from 'jsonexport/dist';
import axios from "axios";
import parse from "html-react-parser";
import { IoMailSharp } from "react-icons/io5";
import { IoIosWarning } from "react-icons/io";
import { useState } from "react";
import SecondaryButton from "Components/Buttons/SecondaryButton";
import PrimaryButton from "Components/Buttons/PrimaryButton";

const UrlDossierCompetence = ({ source }) => {
  const record = useRecordContext();
  if (!record) return null;
  let url = window.location.origin + "/dossier-de-competences/" + record.originId;
  return <a href={url} className="text-[#497EA6] hover:underline">consulter le dossier de compétence</a>;
};
const LimitPresentation = ({ source }) => {
  const record = useRecordContext();
  if (!record.presentation) return null;
  if (record.presentation.length > 40) {
    return parse(record.presentation.substring(0, 40) + "...");
  } else {
    return parse(record.presentation);
  }
};

const MailTo = () => {
  const record = useRecordContext();
  return <a className='text-[#497EA6] hover:underline' href={`mailto:${record.email}`}>{record.email}</a>
}

function MailAccountNotValid() {
  const record = useRecordContext();
  const [show, setShow] = useState(false)
  return (
    <>
      <div className="w-[130px] text-center flex items-center gap-2 text-[#38a9b4]" onClick={() => { setShow(true) }}>
        <IoMailSharp size={18} />
        <span>Envoyer mail</span>
      </div>
      {show &&
        <>
          <div className="fixed z-50 inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center border" />
          <div className="fixed z-50 max-w-[80vw] -translate-x-2/4 top-[40vh] left-[50%] max-h-[85vh] bg-white rounded-[5px]  py-8 px-12">
            <div className="flex w-full justify-center">
              <span className='text-[#FF0000] bg-[#FCDCDC] inline-block rounded-full p-4'>
                <IoIosWarning size={72} />
              </span>
            </div>
            <p className="font-medium text-black text-2xl text-center mt-4">Envoyé le mail à <span className="text-[#5B9ECF]">{record.firstName} {record.lastName}</span></p>
            <div className='flex items-center justify-end gap-2 mt-4'>
              <SecondaryButton onClick={() => setShow(false)} text={"Annuler"} />
              <PrimaryButton text={"Envoyer le mail (EN ATTENTE DU TEXTE)"} />
            </div>
          </div>
        </>
      }
    </>
  )
}

const exporter = posts => {
  axios.get(process.env.REACT_APP_API + `filtres`)
    .then((response) => {
      const postsForExport = posts.map(post => {
        let arrayTag = { secteur: [], intervention: [], fonctionnelle: [], informatique: [], linguistique: [] };
        post.tags.forEach(userTag => {
          let isNotFound = true;
          Object.keys(arrayTag).forEach(val => {
            if (isNotFound) {
              const addTag = response.data[val].find((Tag) => Tag.id === parseInt(userTag.tag.id));
              if (addTag !== undefined) {
                const level = val !== "intervention" ? ",niveau:" + userTag.level : ""
                arrayTag[val].push(addTag.name + level)
                isNotFound = false;
              }
            }
          })
        })
        let arrayGrade = [];
        post.idgrade.forEach(userGrade => {
          const addGrade = response.data["grade"].find((Grade) => Grade.id === parseInt(userGrade.split("/").pop()));
          if (addGrade !== undefined) {
            arrayGrade.push(addGrade.name)
          }
        })
        const postForExport = {
          "Anonymous": post.anonymous,
          "Prénom": post.first_name,
          "Nom": post.last_name,
          "Contrats": post.work_experience,
          "Titre": post.titre,
          "Téléphone": post.mobile !== undefined ? ("(+33) " + post.mobile) : (null),
          "Email": post.email,
          "Présentation": post.presentation,
          "Rôles": post.roles[0],
          "Date de création": new Date(post.date_time_creation).toLocaleString('fr-FR', { day: "2-digit", month: "numeric", year: "numeric", hour: "2-digit", minute: "2-digit" }),
          "Compétences sectorielles": arrayTag.secteur,
          "Compétences fonctionnelles": arrayTag.fonctionnelle,
          "Compétences informatiques": arrayTag.informatique,
          "Compétences linguistiques": arrayTag.linguistique,
          "Intervention": arrayTag.intervention,
          "Grade": arrayGrade,
          "Salaire": post.salaire,
          "TJM": post.jtm,
          "Temps experience": post.time_experience,
          "Departement": post.department,
          "Localisation": post.localisation
        };
        return postForExport;
      });
      jsonExport(postsForExport, {
        headers: [], // order fields in the export
        rowDelimiter: ";"
      }, (err, csv) => {
        downloadCSV('\ufeff' + csv, 'Profils'); // download as csv file
      });
    })
    .catch((error) => {
      // console.error(error);
    });
};

const ListFilters = [
  <TextInput label="ID" source="anonymous" alwaysOn />,
  <TextInput label="Prénom" source="first_name" alwaysOn />,
  <TextInput label="Nom" source="last_name" alwaysOn />,
  <TextInput label="Mobile" source="mobile" alwaysOn />,
  <TextInput label="Email" source="email" alwaysOn />,
  <SelectInput label="Role" source="roles" choices={[
    { id: "Candidat", name: "Candidat" },
    { id: "Consultant", name: "Consultant" },
    { id: "Etudiant", name: "Etudiant" }
  ]} alwaysOn />,
];

const UserList = (props) => {
  const { permissions } = usePermissions();
  return (
    <ListGuesser {...props} sort={{ field: 'date_time_creation', order: 'DESC' }} perPage={25} filters={ListFilters} exporter={exporter}>
      <FieldGuesser label="ID" source="anonymous" />
      <FieldGuesser label="Prénom" source="first_name" />
      <FieldGuesser label="Nom" source="last_name" />
      <FieldGuesser label="Contrat" source="work_experience" />
      <FieldGuesser label="Titre" source="titre" />
      <FieldGuesser label="Téléphone" source="mobile" />
      <MailTo label="Mail" source="email" />
      <LimitPresentation label="Présentation" source="presentation" />
      <FieldGuesser label="Rôles" source="roles" />
      <FieldGuesser label="Date de création" source="date_time_creation" />
      <FieldGuesser label="afficher le profil" source="visibility" />
      <FieldGuesser label="Verif" source="verif" />
      <UrlDossierCompetence label="dossier de compétence" source="id" />
      <MailAccountNotValid label="Compte non conforme" />
      {permissions === "ROLE_SUPER_ADMIN" &&
        <DeleteWithConfirmButton confirmContent="You will not be able to recover this record. Are you sure?" />
      }
    </ListGuesser>
  );
};
const UserShow = (props) => {
  const { permissions } = usePermissions();
  return (
    <ShowGuesser {...props}>
      {permissions === "ROLE_SUPER_ADMIN" &&
        <DeleteWithConfirmButton confirmContent="You will not be able to recover this record. Are you sure?" />
      }
      <FieldGuesser label="ID anonymous" source="anonymous" />
      <FieldGuesser label="Prénom" source="first_name" />
      <FieldGuesser label="Nom" source="last_name" />
      <FieldGuesser label="Téléphone" source="mobile" />
      <FieldGuesser label="Titre" source="titre" />
      <MailTo label="Email" source="email" />
      <LimitPresentation label="Présentation" source="presentation" />
      <FieldGuesser label="date de création de l'utilisateur" source="date_time_creation" />
      <FieldGuesser label="Contrat" source="work_experience" />
      <FieldGuesser label="Rôles" source="roles" />
      <FieldGuesser label="afficher le profil" source="visibility" />
      <FieldGuesser label="Rendre le profil non visible" source="verif" />
      <UrlDossierCompetence label="dossier de compétence" source="id" />
      <FieldGuesser label="SUPRA" source="supra" />
      <FieldGuesser label="Note" source="iris_note" />
    </ShowGuesser>
  );
};
const UserEdit = (props) => (
  <EditGuesser {...props}>
    <InputGuesser source="visibility" label="Rendre le profil non visible" />
    <InputGuesser source="irisNote" label="Note" />
  </EditGuesser>
);
const UserSuperCreate = (props) => (
  <CreateGuesser {...props}>
    <InputGuesser source="visibility" label="Rendre le profil non visible" />
    <InputGuesser source="irisNote" label="Note" />
    <InputGuesser source="email" />
    <InputGuesser source="password" />
    <InputGuesser source="first_name" />
    <InputGuesser source="last_name" />
    <SelectInput source="roles" choices={[
      { id: "Candidat", name: "Candidat" },
      { id: "Consultant", name: "Consultant" }
    ]} />
  </CreateGuesser>
);

export { UserList, UserShow, UserEdit, UserSuperCreate };