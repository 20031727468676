import React, { useState, useEffect, useContext } from "react";
import { Link, useSearchParams } from "react-router-dom";
import axios from "axios";
import cookie from "js-cookie";
import ProfilCard from "../Cards/ProfilCard"
import SearchBarre from "../Filtres/SearchBarre";
import Filtres from "Pages/Statics/Filtres/FiltreMenue";
import { CheckboxContext } from "../LesOpportunite/Context/CheckboxProvider";
import MobileFilter from "Components/MobileFilter/MobileFilter";
import Pagination from "Components/Pagination/Pagination";
import MobileFilter2 from "Components/MobileFilter/MobileFilter2";
import { LuLoader2 } from "react-icons/lu";
import { Helmet } from "react-helmet";  

const accents = require("remove-accents");

function Profil(props) {
  const { checkBoxValues, setCheckboxes, resetFilters } = useContext(CheckboxContext);
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [search, setSearch] = useState([]);
  const [mergeTerm, setmergeTerm] = useState([]);
  const [filterBase, setFilterBase] = useState();
  const [filterArray, setFilterArray] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [btnIsActive, setBtnIsActive] = useState({})
  const [nbResult, setNbResult] = useState(null);
  const [searchParams] = useSearchParams();

  const [currentPage, setCurentPage] = useState(1);
  const [nbPage, setNbPage] = useState();
  const [timer, setTimer] = useState();
  const token = cookie.get("token");

  let localisation = searchParams.get('localisation')
  let searchTermQuery = searchParams.get('searchTerm') ?? ""
  let profilType = searchParams.get('type')

  useEffect(() => {
    if (localisation && localisation.length > 0) {
      if (checkBoxValues.checkboxes["champLibre"] == "" && searchTermQuery.length > 0) {
        checkBoxValues.checkboxes["champLibre"] = searchTermQuery;
      }
      if (!checkBoxValues.checkboxes["localisation"].includes(+localisation)) {
        let filterSelected = checkBoxValues.checkboxes["localisation"]
        filterSelected.push(+localisation)
        setCheckboxes(prevData => ({
          ...prevData,
          ["localisation"]: filterSelected
        }))
      }
    }
  }, [localisation])

  useEffect(() => {
    if (profilType != null) {
      switch (profilType) {
        case "ecole":
          checkBoxValues.checkboxes["contrat"] = [1, 2]
          break;
        case "recrutement":
          checkBoxValues.checkboxes["contrat"] = [3, 4]
          break;
        case "consulting":
          checkBoxValues.checkboxes["contrat"] = [6]
          break;
        default:
          break;
      }
    }
  }, [profilType])

  useEffect(() => {
    if (searchTermQuery !== "" && !checkBoxValues.checkboxes["champLibre"]) {
      checkBoxValues.checkboxes["champLibre"] = searchTermQuery;
    }
  }, [searchTermQuery])

  function getProfiles() {
    setIsLoading(true); // Définir l'état de chargement à true
    axios.post(process.env.REACT_APP_API + `Lesprofils/${currentPage}`, checkBoxValues.checkboxes, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token ? `Bearer ${token}` : ""
      },
    })
      .then((res) => {
        if (res.data.nbResult > 0) {
          setData(res.data.profil)
        } else {
          setData([])
        }
        setNbPage(Math.ceil(res.data.nbResult / 20))
        setIsLoading(false); // Définir l'état de chargement à false après avoir récupéré les données
      })
      .catch((error) => {
        console.log(error)
        setIsLoading(false); // Définir l'état de chargement à false après avoir récupéré les données
      });
  }

  const onPageChangeHandler = (page) => {
    window.scrollTo(0, 0)
    setCurentPage(page)
  }

  const handleReset = () => {
    setSearch("")
    setSearchTerm([])
    setFilterArray([])
    setmergeTerm({})
    setBtnIsActive({})
    checkBoxValues.setCheckboxes({
      contract: [],
      others: []
    });
  }

  // récupère la data des boutons
  const handleBase = (e) => {
    setFilterBase(e.filtre);
  }

  const handleSearchTerm = (e) => {
    setSearchTerm(e);
  }

  const handleFilter = (e) => {
    setFilterArray(e);
  }

  useEffect(() => {
    getProfiles()
  }, [currentPage]);

  useEffect(() => {
    setCurentPage(1)
    //On met un timeout pour éviter les spams de requetes quand le user écrit vite
    clearTimeout(timer);
    setTimer(setTimeout(() => {
      getProfiles()
    }, 500));//500 ms
  }, [checkBoxValues.checkboxes])


  return (
    <>
      <div className="containerxl">
      <Helmet>  
                  <title>MyFinPlace - les profils</title>  
                </Helmet>  
        <div className="flex py-4 gap-4">
          <div className="hidden md:block md:col-span-4 lg:col-span-3 xl:col-span-2">
            <div className="bg-white rounded border sticky top-[114px] sm:w-[268px]">
              <Filtres handleSearchTerm={handleSearchTerm} search={search} searchTermQuery={searchTermQuery} onFilter={handleFilter} onEmpty={handleReset} resetFilters={resetFilters} base={filterBase} />
            </div>
          </div>
          <div className="flex-1">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 mb-4">
              {isLoading ?
                <LuLoader2 className='animate-spin text-[#5B9ECF]' size={26} />
                :
                data.length === 0 ?
                  <p>Il n'y a pas de profils</p>
                  :
                  data.map((card, key) => (
                    <ProfilCard key={key} data={card} />
                  ))
              }
            </div>
            <div>
              <Pagination totalPages={nbPage} currentPage={currentPage} onPageChange={onPageChangeHandler} />
            </div>
          </div>
        </div>
      </div>
      <MobileFilter2 handleSearchTerm={handleSearchTerm} search={search} searchTermQuery={searchTermQuery} onFilter={handleFilter} onEmpty={handleReset} resetFilters={resetFilters} base={filterBase} />
    </>
  );
}

export default Profil;
