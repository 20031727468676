import { useEffect, useContext, useState } from "react"
import axios from "axios";
import cookie from "js-cookie";
import { FaMagnifyingGlass } from "react-icons/fa6";
import Card from "./Card";
import Pagination from "Components/Pagination/Pagination";
import { AuthContext } from "Context/AuthProvider"
import { isClient, isUser } from 'utils/roles';
import { useNavigate } from "react-router-dom";
import { LuLoader2 } from "react-icons/lu";
import Femme_pouces from "assets/img/missdata/Femme_pouces.png";

export default function FavorisRecommandation() {
  const [status, setStatus] = useState("favoris");
  const [isLoading, setIsLoading] = useState(true);
  const [recherche, setRecherche] = useState("");
  const [data, setData] = useState([]);
  const [nbPage, setNbPage] = useState(1);
  const [currentPage, setCurentPage] = useState(1);
  const token = cookie.get("token");
  const { value } = useContext(AuthContext);
  const redirection = useNavigate();

  function fetchProfil() {
    setIsLoading(true)
    setData([])
    const titre = recherche !== "" ? `&titre=${recherche}` : ""
    axios.get(process.env.REACT_APP_API + `client/${status}?page=${currentPage}${titre}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then((response) => {
        setData(response.data.dataProfil);
        setNbPage(Math.ceil(response.data.nbProfil / 20));
        console.log(response.data.dataProfil)
      })
      .catch((error) => {
      })
      .finally(() => {
        setIsLoading(false)
      });
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      fetchProfil()
    }, 200);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [recherche])

  useEffect(() => {
    fetchProfil()
  }, [status, currentPage])

  useEffect(() => {
    if (value.isLoggedIn && isUser(value.user)) {
      redirection("/mon-espace")
    }
  }, [value])

  const onPageChangeHandler = (page) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
    setCurentPage(page)
  }

  function handleRecherche(e) {
    setRecherche(e.target.value)
  }
  function handleStatus(e) {
    setStatus(e.target.value)
    setCurentPage(1)
  }

  if (isClient(value.user)) {
    return (
      <div className="p-8 mb-8 min-h-[calc(100vh-100px)]">
        <div className="flex flex-col md:flex-row gap-2 mb-4">
          <div className={`${status === "favoris" ? "bg-[#2463EB] text-white" : ""} p-2 rounded border  cursor-pointer hover:bg-[#2463EB] hover:text-white`} onClick={() => setStatus("favoris")}>
            <span>Favoris</span>
          </div>
          <div className={`${status === "recommendation" ? "bg-[#2463EB] text-white" : ""} p-2 rounded border  cursor-pointer hover:bg-[#2463EB] hover:text-white`} onClick={() => setStatus("recommendation")}>
            <span>Recommandations</span>
          </div>
        </div>
        <hr />
        {/* <div className="flex justify-end">
          <div className="flex gap-4">
            <div className="border flex items-center bg-white rounded-[5px] pr-4">
              <input onChange={handleRecherche} className="w-full outline-none py-2 px-4" type="text" placeholder="Rechercher par poste" />
              <FaMagnifyingGlass className="hover:cursor-pointer" />
            </div>
            <select onChange={handleStatus} class="bg-white border border-middleblue text-middleblue text-sm rounded-lg focus:ring-middleblue focus:border-middleblue block py-2 px-4">
              <option value="favoris" selected>Favoris</option>
              <option value="recommendation">Recommandation</option>
            </select>
          </div>
        </div> */}
        {
          isLoading ?
            <div className="mt-4 flex justify-center">
              <div className="w-[280px] h-[280px] flex justify-center items-center cursor-pointer">
                <div className="text-[#2563EB]">
                  <LuLoader2 size={48} className='animate-spin' />
                </div>
              </div>
            </div>
            :
            null
        }
        <div className="flex flex-col md:flex-row gap-4 mt-4 flex-wrap">
          {data !== undefined &&
            data.length > 0 ?
            data.map((user) => {
              return <Card favoris={user} user={user.id_user} redButton={status} fetchProfil={fetchProfil} />
            }) :
            !isLoading &&
            <div className="flex flex-col items-center justify-center my-2 w-full">
              <img className="" src={Femme_pouces} alt="MyFinPlace.com" />
              <h3 className=" px-2 mx-2 font-bold">
                {status === "favoris" ? "Aucun favoris " : "Aucune recommandation "} pour le moment
              </h3>
            </div>}
        </div>


        {
          nbPage > 1 ?
            <Pagination totalPages={nbPage} currentPage={currentPage} onPageChange={onPageChangeHandler} MaxSlot={5} />
            :
            null
        }
      </div>
    )
  }
}