import { Text, View } from '@react-pdf/renderer';
import FluentText from "./Svg/FluentText";
import Map from "./Svg/Map";
import { styles, SizeTitle } from "./../Css/styles";

const TopRight = ({ data }) => (
  <View style={{ width: "80%" }}>
    <Text style={styles.TitreUser}>Dossier de compétences</Text>
    <Text style={styles.TitrePdf}>{data.titre}</Text>
    {(data.expertComptable || data.commissaireCompte) &&
      <Text style={styles.ExpertComptable}>
        {data.expertComptable && "Expert Comptable"}
        {data.expertComptable && data.commissaireCompte && " / "}
        {data.commissaireCompte && "Commissaires aux Comptes"}
      </Text>
    }
    <View style={{ display: "flex", flexDirection: "row", gap: 12, width: "100%", marginTop: 30 }}>
      {/* Contrat */}
      <View style={{ display: "flex", flexDirection: "column", minWidth: "50%" }}>
        <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "center", gap: 4 }}>
          {data.work_experience.map((value, key) => {
            var colorContrat = "";
            switch (value) {
              case "Stage":
                colorContrat = "#B3D8F2"
                break
              case "Alternance":
                colorContrat = "#B3D8F2"
                break
              case "CDD":
                colorContrat = "#B3E7FC"
                break
              case "CDI":
                colorContrat = "#B3E7FC"
                break
              case "Mission":
                colorContrat = "#7CC8FF"
                break
              default:
                break;
            }
            return <View style={{ marginTop: 4 }} key={key}>
              <Text style={{
                backgroundColor: colorContrat,
                textAlign: "center",
                padding: "4px 8px",
                borderRadius: 5,
                color: "black",
                fontSize: 8,
                fontFamily: 'Poppins',
                fontWeight: 800
              }}>
                {value}
              </Text>
            </View>
          }
          )}
        </View>
      </View>
      {/* Localisation */}
      <View style={{ display: "flex", flexDirection: "column", minWidth: "50%" }}>
        <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "center", gap: 4 }}>
          <Map />
          <Text style={styles.Tag}>
            {data.department}
          </Text>
        </View>
      </View>
    </View>
  </View>
);

export default TopRight;