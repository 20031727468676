import { useState, useEffect, useContext } from 'react';
import PrimaryButton from 'Components/Buttons/PrimaryButton'
import SecondaryButton from 'Components/Buttons/SecondaryButton'
import Wysiwig from "Components/wysiwig/wysiwig";
import { useForm } from 'react-hook-form'
import Cookies from "js-cookie";
import axios from "axios";
import { AlertMessageContext } from 'Context/AlertMessageProvider';
import { MonthPicker, HandleShowMonth } from 'Components/Forms/MonthPicker/MonthPicker';
import { CiCalendar } from "react-icons/ci";

function CreateExperienceModal({ experience = null, setUserInformation, close, getPourcentage }) {
    const maxCaracteres = 1500;
    const [description, setDescription] = useState("")
    const [isChecked, setIsChecked] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const token = Cookies.get("token");
    const { register, handleSubmit, setValue, setError, formState: { errors, isDirty } } = useForm({
        mode: "onSubmit"
    });

    const { alert } = useContext(AlertMessageContext);

    const handleDescription = (e) => {
        setDescription(e);
    };


    useEffect(() => {
        if (experience) {
            setValue("name", experience.name)
            setValue("poste", experience.poste)
            const dateStart = experience.date_start.split('T')[0].split("-")
            setValue("date_start", dateStart[1] + '/' + dateStart[0])
            setValue("in_progress", experience.in_progress)
            setDescription(experience.description)
            setIsChecked(experience.in_progress)
        }
        if (experience && experience.date_end) {
            const dateEnd = experience.date_end.split('T')[0].split("-")
            setValue("date_end", dateEnd[1] + '/' + dateEnd[0])
        }
    }, [])

    const onSubmit = async (formData) => {
        setIsLoading(true);
        const data = {
            poste: formData.poste,
            name: formData.name,
            inProgress: formData.in_progress,
            dateStart: formData.date_start.slice(0, 3) + "01/" + formData.date_start.slice(4, 7),
            description: description
        }

        if (formData.date_end) {
            data.dateEnd = formData.date_end.slice(0, 3) + "01/" + formData.date_end.slice(4, 7)
        }

        if (!data.inProgress && (new Date(data.dateStart) >= new Date(data.dateEnd))) {
            setError("date_end", { type: "custom", message: "Votre date de fin ne peut pas être postérieure à votre date de début" })
            setIsLoading(false);
            return
        }

        if (data.description.length > maxCaracteres) {
            setIsLoading(false);
            return
        }

        try {
            if (experience) {
                const res = await editExperience(data)

                const updatedExperience = {
                    name: res.data.name,
                    poste: res.data.poste,
                    in_progress: res.data.inProgress,
                    date_start: res.data.dateStart,
                    description: res.data.description,
                    id: res.data.id
                }

                if (res.data.dateEnd) {
                    updatedExperience.date_end = res.data.dateEnd
                }

                setUserInformation(prev => (
                    {
                        ...prev,
                        experience: prev.experience.map(e => {
                            if (e.id == experience.id) {
                                return updatedExperience
                            } else {
                                return e
                            }
                        })
                    }
                ))
            } else {
                const newExperience = await createExperience(data)
                setUserInformation(prev => ({ ...prev, experience: [newExperience, ...prev.experience] }))
                getPourcentage()

            }
            alert.successAlert(experience ? "Votre expérience a été mise à jour." : "Votre expérience a été ajoutée.");
            setIsLoading(false);
            close()
        } catch (error) {
            setIsLoading(false);
            console.log(error)
        }
    }

    const editExperience = async (data) => {
        const url = `${process.env.REACT_APP_API}user/experience/modify/${experience.id}`
        const header = {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/merge-patch+json"
            }
        }

        const res = await axios.patch(url, data, header)
        return res
    }

    const createExperience = async (data) => {
        try {
            const url = `${process.env.REACT_APP_API}user/experience/create`
            const header = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json"
                }
            }

            const res = await axios.post(url, data, header)

            const newExperience = {
                name: res.data.name,
                poste: res.data.poste,
                in_progress: res.data.inProgress,
                date_start: res.data.dateStart,
                description: res.data.description,
                id: res.data.id
            }

            if (res.data.dateEnd) {
                newExperience.date_end = res.data.dateEnd
            }

            return newExperience

        } catch (error) {
            throw error
        }
    }

    function MonthMask(ev) {
        var text = ev.target.value
        text = text.replace(/[^0-9.]/g, '');
        if (text.length >= 2) {
            text = text.slice(0, 2) + "/" + text.slice(2);
        }
        if (text.length > 7) {
            text = text.slice(0, 7);
        }
        ev.target.value = text;
    }

    return (
        <div>
            <form action="" onSubmit={handleSubmit(onSubmit)}>
                <div className='mb-4'>
                    <div className='grid grid-cols-6 gap-2 mb-4'>
                        <div className='col-span-6'>
                            <label htmlFor="name" className='block font-medium leading-6 text-gray-900'>Nom de l'entreprise</label>
                            <input name='name' id='name' className='py-2 px-2 ring-1 ring-gray-300 border-lightblue rounded-md w-full outline-none focus:ring-[#2563EB] focus:ring-2' type="text" {...register("name", { required: "Ce champ est requis" })} />
                            {errors.name && (
                                <span className="text-red-500 text-xs font-bold mt-1">
                                    {errors.name.message}
                                </span>
                            )}
                        </div>
                        <div className='col-span-6'>
                            <label htmlFor="poste" className='block font-medium leading-6 text-gray-900'>Poste</label>
                            <input name='poste' id='poste' className='py-2 px-2 ring-1 ring-gray-300 border-lightblue rounded-md w-full outline-none focus:ring-[#2563EB] focus:ring-2' type="text" {...register("poste", { required: "Ce champ est requis" })} />
                            {errors.poste && (
                                <span className="text-red-500 text-xs font-bold mt-1">
                                    {errors.poste.message}
                                </span>
                            )}
                        </div>
                        <div className='col-span-6 sm:col-span-3'>
                            <label htmlFor="dateStart" className='block font-medium leading-6 text-gray-900'>Date de debut</label>
                            <div className='relative'>
                                <input name='dateStart' id='dateStart' onInput={MonthMask} placeholder='MM/YYYY' className='py-2 px-2 ring-1 ring-gray-300 border-lightblue rounded-md w-full outline-none focus:ring-[#2563EB] focus:ring-2' type="text" pattern='[0-9]{2}/[0-9]{4}' {...register("date_start", { required: "Ce champ est requis" })} />
                                <div id="CiCalendar1" className='absolute top-1 right-2'>
                                    <CiCalendar size={25} onClick={() => HandleShowMonth("1")} />
                                    <span className='hidden' id='MonthPicker1'>
                                        <MonthPicker setValue={setValue} labelValue="date_start" idInput="dateStart" />
                                    </span>
                                </div>
                            </div>
                            {errors.date_start && (
                                <span className="text-red-500 text-xs font-bold mt-1">
                                    {errors.date_start.message}
                                </span>
                            )}
                        </div>
                        {
                            !isChecked ?
                                <div className='col-span-6 sm:col-span-3'>
                                    <label htmlFor="dateEnd" className='block font-medium leading-6 text-gray-900'>Date de fin</label>
                                    <div className='relative'>
                                        <input name='dateEnd' id='dateEnd' onInput={MonthMask} placeholder='MM/YYYY' className='py-2 px-2 ring-1 ring-gray-300 border-lightblue rounded-md w-full outline-none focus:ring-[#2563EB] focus:ring-2' type="text" pattern='[0-9]{2}/[0-9]{4}' {...register("date_end", { required: "Ce champ est requis" })} />
                                        <div id="CiCalendar2" className='absolute top-1 right-2'>
                                            <CiCalendar size={25} onClick={() => HandleShowMonth("2")} />
                                            <span className='hidden' id='MonthPicker2'>
                                                <MonthPicker setValue={setValue} labelValue="date_end" idInput="dateEnd" />
                                            </span>
                                        </div>
                                    </div>
                                    {errors.date_end && (
                                        <span className="text-red-500 text-xs font-bold mt-1">
                                            {errors.date_end.message}
                                        </span>
                                    )}
                                </div>
                                :
                                <div className='col-span-6 sm:col-span-3'>
                                    <label htmlFor="dateEnd" className='block font-medium leading-6 text-gray-900'>Date de fin</label>
                                    <input name='dateEnd' id='dateEnd' className='py-2 px-2 ring-1 ring-gray-300 border-lightblue rounded-md w-full outline-none focus:ring-[#2563EB] focus:ring-2' type="text" readOnly placeholder='En cours' />
                                </div>

                        }
                        <div className='col-span-3'>
                        </div>
                        <div className='col-span-3'>
                            <div className='flex gap-1 items-center'>
                                <input className='cursor-pointer' type={"checkbox"} onClick={(e) => setIsChecked(e.target.checked)} name='in_progress' id='in_progress' {...register("in_progress")} />
                                <label htmlFor='in_progress' className='cursor-pointer font-bold'>En cours</label>
                            </div>
                        </div>
                    </div>
                    <div>
                        <Wysiwig text={description} setText={handleDescription} />
                        {maxCaracteres &&
                            <p className={`text-right ${description.length > maxCaracteres && "text-red-500"}`}>
                                {description ? description.length : 0} / {maxCaracteres}
                            </p>
                        }
                    </div>

                </div>
                <div className='flex items-center justify-end gap-2'>
                    <SecondaryButton onClick={() => close()} text={"Annuler"} />
                    <PrimaryButton type={"submit"} isLoading={isLoading} text={experience ? "Enregistrer" : "Ajouter"} />
                </div>
            </form>
        </div>
    )
}

export default CreateExperienceModal